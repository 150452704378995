import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import { defaultInputStyle } from "../../../styles/cssInput.js";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledLiveEventsCumulative = styled.div`
  ${StyledBlockWrapper} {
    .title {
      ${blockTitle};
    }

    .js-plotly-plot {
      width: 100%;
      height: 160px;

      .js-fill {
        opacity: 0.2;
      }
    }

    input {
      ${defaultInputStyle};

      width: 100%;
      margin-top: 20px;

      padding-top: ${cssVars.distances.paddingBox};
      padding-bottom: ${cssVars.distances.paddingBox};
    }
  }
`;

export default StyledLiveEventsCumulative;
