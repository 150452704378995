import styled from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import StyledButton from "../../UI/Button/Button.styles.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledConfirmActionModal = styled.div`
  position: fixed;

  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;

  z-index: 1000;

  .background {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: ${cssVars.colors.blueLight};
    opacity: 0.4;
  }

  ${StyledBlockWrapper} {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    p {
      margin-bottom: 30px;
    }

    ${StyledButton} {
      display: inline-block;

      float: right;

      button {
        min-width: 60px;

        margin-left: 10px;
      }
    }
  }
`;

export default StyledConfirmActionModal;
