import React from "react";
import DataTable, { TableProps } from "react-data-table-component";
import cssVars from "../../../styles/cssVars.js";
import StyledTableBasic from "./TableBasic.styles.js";

//see: https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js
const customStyles = {
    header: {
        style: {
            display: "none",
        },
    },
    headCells: {
        style: {
            fontWeight: 700,
            fontSize: "16px",
        },
    },
    rows: {
        style: {
            fontSize: "14px",
        },
        stripedStyle: {
            color: cssVars.colors.white,
            backgroundColor: cssVars.colors.blueLightLight,
        },
    },
};

const TableBasic: React.FC<TableProps<any>> = ({
    columns,
    data,
}: TableProps<any>) => {
    return (
        <StyledTableBasic>
            <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
            />
        </StyledTableBasic>
    );
};

export default TableBasic;
