import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RESTService from "../../../services/RESTService.js";

type EmailVerificationPageParamProps = {
    user_id: string;
    verification_token: string;
};

const EmailVerificationPage: React.FC = () => {
    const history = useNavigate();
    const { user_id, verification_token } =
        useParams<EmailVerificationPageParamProps>();

    useEffect(() => {
        const values = {
            id: user_id,
            token: decodeURIComponent(verification_token),
        };

        void RESTService.post({
            url: "Auth/ConfirmEmail",
            payload: values,
            successCallback(res: AxiosResponse): void {
                history("/login?emailverified");
            },
            errorCallback(res: AxiosError): void {
                history("/verify/error");
            },
        });
    }, []);

    return <></>;
};

export default EmailVerificationPage;
