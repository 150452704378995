import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn } from "./base.js";
import { MeData } from "../../api/v1/User/me.js";

interface MeOptions {
    enabled?: boolean;
}

interface InvalidateOptions {
    queryClient: QueryClient;
}

const queryKey = ["User", "me"];

export function useMe({
    enabled: enabledOption,
}: MeOptions): UseQueryResult<MeData> {
    const enabled = enabledOption === undefined || enabledOption;

    return useQuery({
        enabled,
        staleTime: 24*60*60*1000,
        gcTime: 60*60*1000,
        queryKey,
        queryFn: baseQueryFn<MeData>,
    });
}

export function invalidateMe({
    queryClient,
}: InvalidateOptions) {
    queryClient.invalidateQueries({queryKey});
}
