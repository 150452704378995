import HTMLReactParser from "html-react-parser";
import React from "react";
import icons from "../../../assets/svgs.js";
import StyledIcon from "./Icon.styles.js";

export type IconName = keyof typeof icons;

type IconProps = {
    name: IconName;
    size?: number;
    className?: string;
    click?: (ev: React.MouseEvent<HTMLElement>) => void;
};

const Icon: React.FC<IconProps> = ({
    name,
    size,
    className,
    click,
}: IconProps) =>
    icons[name] ? (
        <StyledIcon size={size} className={className} onClick={click}>
            {HTMLReactParser(icons[name])}
        </StyledIcon>
    ) : null;

export default Icon;
