import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HourlyPresenceData } from "../../api/v1/ReportData/index.js";
import RESTService from "../../services/RESTService.js";
import { formatApiDate, startOfWeek } from "../../utils/date-time.js";

interface PeakPresenceDataOptions {
  spaceId: string;
  date: number;
}

async function queryFn({ queryKey }) {
  const [_spaces, spaceId, _peakpres, strDate] = queryKey;

  const dateNum: number = +strDate;
  const startDate = startOfWeek(dateNum);

  const params = new URLSearchParams();
  params.append("week", formatApiDate(startDate));

  return await RESTService.getAsync<HourlyPresenceData>({
    url: `Spaces/${spaceId}/PeakPresence?${params.toString()}`,
  });
}

export function usePeakPresenceData({
  spaceId,
  date,
}: PeakPresenceDataOptions): UseQueryResult<HourlyPresenceData> {
  const queryKey = ["Spaces", spaceId, "PeakPresence", date.toString()];
  const enabled = !!spaceId;
  const staleTime = 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn,
    staleTime,
  });
}
