import { useQueryClient } from "@tanstack/react-query";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { HeatmapData } from "../../../api/v1/ReportData/index.js";
import { HeatmapInfo } from "../../../api/v1/ReportInfo/heatmap.js";
import {
  useSpaceHeatmaps,
  useSpaceHeatmapsLatestData,
} from "../../../hooks/api.js";
import { useWebSocketUpdatedMultiQuery } from "../../../hooks/websocketQuery.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledHeatmap from "./Heatmap.styles.js";
import { HeatmapRender } from "./HeatMapRender.js";

type HeatMapConfigProps = {
  children: React.ReactNode;
  organizationId: string;
  spaceId: string;
  radius?: number;
  scaling?: number;
  image: { width: number };
  heatmap: {
    positionX: number;
    positionY: number;
    width: number;
  };
  isDebug?: boolean;
  debugCallback?: (info: object) => void;
};

export const DEFAULTS = {
  heatmap_width: 1024,
  heatmap_pos_x: 0,
  heatmap_pos_y: 0,
  radius_multiplier: 1.0,
  scaling_exponent: 1.0,
};

type WidthHeight = {
  width: number;
  height: number;
};

// Transforms sizes in meters to sizes in pixels
const getRelativeCoordinates = (
  coordinates: [number, number],
  containerSize: WidthHeight,
  total_size: [number, number],
): [number, number] => {
  if (!containerSize) return [0, 0];

  const { width, height } = containerSize;

  return [
    (coordinates[0] / total_size[0]) * width,
    (coordinates[1] / total_size[1]) * height,
  ];
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let timeoutId: any;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowDimensions;
};

type Boundaries = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

const getBoundariesOfCoordinates = (coordinates: number[][]): Boundaries => ({
  left: coordinates.reduce((acc, [x, y]) => (acc > x ? x : acc), Infinity),
  right: coordinates.reduce((acc, [x, y]) => (acc < x ? x : acc), -Infinity),
  top: coordinates.reduce((acc, [x, y]) => (acc < y ? y : acc), -Infinity),
  bottom: coordinates.reduce((acc, [x, y]) => (acc > y ? y : acc), Infinity),
});

const useGridBoundaries = (zones: HeatmapInfo[]) => {
  const boundaries = useMemo<Boundaries>(() => {
    const coords = zones.flatMap((zone) => zone.coordinates);
    return getBoundariesOfCoordinates(coords);
  }, [zones]);

  return boundaries;
};

const useContainerBoundaries = (container: RefObject<HTMLDivElement>) => {
  const [boundaries, setBoundaries] = useState<WidthHeight>();

  useEffect(() => {
    if (container.current) {
      const { width, height } = container.current.getBoundingClientRect();
      if (
        !boundaries ||
        width != boundaries.width ||
        height != boundaries.height
      )
        setBoundaries({ width, height });
    }
  });

  return boundaries;
};

const getDimensionsFromBoundaries = (boundaries: Boundaries) => {
  return {
    width: boundaries.right - boundaries.left,
    height: boundaries.top - boundaries.bottom,
  };
};

const HeatMap: React.FC<HeatMapConfigProps> = ({
  children,
  organizationId,
  spaceId,
  radius,
  scaling,
  image,
  heatmap,
  isDebug,
  debugCallback,
}: HeatMapConfigProps) => {
  const radiusMultiplier = radius ?? 1.0;
  const scalingExponent = scaling ?? 1.0;
  const queryClient = useQueryClient();
  const container = useRef<HTMLDivElement>(null);
  const containerSize = useContainerBoundaries(container);
  const imageRef = useRef<HTMLDivElement>(null);

  const { data: zones } = useSpaceHeatmaps({ spaceId });
  const { data: heatmapData, queryKey } = useSpaceHeatmapsLatestData({
    spaceId,
    queryClient,
  });
  const groupPrefix = useMemo(() => `heatmap-${spaceId}`, [spaceId]);
  const items = useMemo(
    () => zones?.map((zone) => zone.identifier) ?? [],
    [zones],
  );

  useWebSocketUpdatedMultiQuery<HeatmapData>({
    queryKey,
    groupPrefix,
    items,
    queryClient,
    organizationId,
  });

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const gridBoundaries = useGridBoundaries(zones ?? []);
  const { width: gridWidth, height: gridHeight } =
    getDimensionsFromBoundaries(gridBoundaries);
  const aspectRatio = gridWidth / gridHeight;

  const dimensionsKey = `${windowWidth}-${windowHeight}`;

  // Filter out the zones that are not relevant for this space
  const dataForAllRelevantZones =
    zones && heatmapData
      ? Object.entries(heatmapData).filter(([key]) =>
          zones.map((zone) => zone.identifier).includes(key),
        )
      : [];

  const outliers_to_remove = 10;

  // NOTE: Temp fix in FE, we need to ask BE to always return grid as an array
  const dataForAllRelevantZonesWithGrid = dataForAllRelevantZones.map(
    (item) => {
      let obj = item[1];

      if (obj === null) {
        obj = { grid: [] };
      } else if (typeof obj === "object" && !obj.hasOwnProperty("grid")) {
        obj.grid = [];
      }
      return [item[0], obj];
    },
  );

  const values_descending = dataForAllRelevantZonesWithGrid
    .flatMap(([zoneId, data]) => {
      if (typeof data === "string") return [];
      return data.grid.flatMap((row) => row.flatMap((val) => val));
    })
    .sort((left, right) => right - left);
  const outlier_cutoff = values_descending[outliers_to_remove];
  const max_value = values_descending[0];
  const total_value = values_descending.reduce((acc, val) => acc + val, 0);
  const num_points = values_descending.length;
  const median_value = values_descending[Math.floor(num_points / 2)];
  const mean_value = total_value / num_points;
  const stddev =
    (values_descending.reduce((acc, val) => acc + (mean_value - val) ** 2, 0) /
      num_points) **
    0.5;

  // Get all the data for the heatmap zones
  // Should be this, but that doesn't work
  //const heatmapValues = containerSize ? null
  const heatmapValues =
    container.current === null
      ? null
      : dataForAllRelevantZonesWithGrid.flatMap(([zoneId, data]) => {
          const grid = typeof data === "object" ? data.grid : [];
          const { grid_unit, coordinates } = zones.find(
            (zone) => zone.identifier === zoneId,
          );

          const offsets = getBoundariesOfCoordinates(coordinates);
          const { width: total_width, height: total_height } =
            getDimensionsFromBoundaries(offsets);

          // TODO: figure out a sensible multiplier, which may have to scale with the grid unit and/or grid size
          const radius = Math.floor(
            (grid_unit / total_width) *
              radiusMultiplier *
              (containerSize?.width ?? 0),
          );

          // Map the data to the grid
          return grid.flatMap((row, x) => {
            return row.map((value, y) => {
              const [relativeX, relativeY] = getRelativeCoordinates(
                [
                  // This probably works, but most of this calculation should be lifted out
                  // Also, this should be multistep:
                  // 1) map grid indexes to 'radar coordinates' through grid_unit
                  // 2) ensure radar coordinates are within bounds
                  // 3) offset radar coordinates to 'room coordinates'
                  // 4) again(?) ensure room coordinates are within bounds
                  // 5) map room coordinates to 'window coordinates'
                  (x + 0.5) * (grid_unit ?? 1) + offsets.left,
                  total_height -
                    ((y + 0.5) * (grid_unit ?? 1) + offsets.bottom),
                  // These 2 inline calculations transform sizes in grid units to sizes in meters
                  // In the y case, these are pre-inverted for display
                ],
                containerSize,
                [gridWidth, gridHeight],
              );
              //if ((x == 0 && y == 0) || ((x == grid.length-1) && (y == row.length-1))) {
              //  console.log(`Mapped ${x},${y} in ${grid.length}x${row.length} (${gridWidth}x${gridHeight} m) to ${relativeX},${relativeY}`, grid_unit, offsets, total_height, container.current?.getBoundingClientRect());
              //}

              // When data points with the same x/y appear, they are simply added together
              // That works, but we perform scaling, so that's not quite correct for overlapping heatmaps
              // TODO: if overlapping heatmaps continue to be used: do the addition on our end, so we can scale properly afterward
              return {
                value: Math.min(outlier_cutoff, value) ** scalingExponent,
                x: relativeX,
                y: relativeY,
                radius,
              };
            });
          });
        });

  useEffect(() => {
    if (debugCallback && max_value)
      debugCallback({
        max: max_value,
        median: Math.round(median_value),
        mean: Math.round(mean_value),
        stddev: Math.round(stddev),
        outlier_cutoff,
        aspect_ratio: aspectRatio,
      });
  }, [
    debugCallback,
    max_value,
    median_value,
    mean_value,
    stddev,
    outlier_cutoff,
  ]);

  const imageResize =
    imageRef.current && image?.width > 0
      ? imageRef.current.offsetWidth / image.width
      : null;

  if (isDebug) {
    if (zones === undefined) return <span>Loading...</span>;
    if (zones.length == 0) return <span>No heatmaps for this area</span>;
    if (zones.every((zone) => zone.coordinates.length == 0))
      return <span>Heatmap zones lack coordinates</span>;

    // The aspect ratio appears to be fixed as soon as we start rendering
    // So don't start rendering until we have it
    // This is now a fallback. Things should have been caught by the above checks
    if (Number.isNaN(aspectRatio)) return <>fallback</>;
    // if (children === undefined) return <span>No image</span>;
  } else {
    if (zones === undefined) return null;
    if (zones.length == 0) return null;
    if (zones.every((zone) => zone.coordinates.length == 0)) return null;
    if (Number.isNaN(aspectRatio)) return null;
  }

  // Do not render Heatmap component if the first child is not an image
  const childArray = React.Children.toArray(children);
  if (childArray.length > 0) {
    const firstChild = childArray[0];
    if (React.isValidElement(firstChild) && firstChild.type !== "img")
      return null;
  }

  return (
    <StyledHeatmap>
      <BlockWrapper>
        <div className="title">Heatmap</div>

        {/* TODO: Add maximise heatmap  */}

        {/* <button
            type="button"
            onClick={() => {
              void 0;
            }}
          >
            <Icon name="maximize" size={24} />
        </button> */}

        <div className="relative max-h-full min-h-0 overflow-hidden">
          <div ref={imageRef}>{children}</div>

          {imageResize === null ? null : (
            <div
              ref={container}
              className="absolute inset-0"
              style={{
                top: `${Number(heatmap.positionY) * imageResize}px`,
                left: `${Number(heatmap.positionX) * imageResize}px`,
                height: `${(Number(heatmap.width) / aspectRatio) * imageResize}px`,
                width: `${Number(heatmap.width) * imageResize}px`,
              }}
            >
              {heatmapValues && heatmapValues.length > 0 ? (
                <HeatmapRender
                  key={dimensionsKey}
                  width={Number(heatmap.width) * imageResize}
                  height={(Number(heatmap.width) / aspectRatio) * imageResize}
                  values={heatmapValues}
                />
              ) : null}
            </div>
          )}
        </div>
      </BlockWrapper>
    </StyledHeatmap>
  );
};

export default HeatMap;
