import React from "react";
import { SpaceData } from "~/api/v1/Space";
import { LocationCard } from "~/components/locations/LocationCard";
import { OccupationState } from "~/constants.js";

type LocationCardPageProps = {
  spaces?: SpaceData[];
  getHref?: (space: SpaceData) => string;
};

const LocationCardPage: React.FC<LocationCardPageProps> = ({
  spaces,
  getHref,
}: LocationCardPageProps) => {
  if (!spaces) return <></>;

  return (
    <div className="grid grid-cols-[repeat(auto-fill,_minmax(256px,_1fr))] gap-3 mt-4">
      {spaces.map((space) => (
        <div className="">
          <LocationCard
            key={`space-${space.identifier}`}
            image={space.image}
            initialCount={0}
            initialMaxCount={space.capacity}
            initialState={OccupationState.Ok}
            locationCity=""
            locationName={space.name}
            href={getHref && getHref(space)}
            locationId={space.identifier}
          />
        </div>
      ))}
    </div>
  );
};

export default LocationCardPage;
