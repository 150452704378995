import styled, { css } from "styled-components";
import { typoInputFont } from "../../../styles/cssTypography.js";
import cssVars from "../../../styles/cssVars.js";

const StyledButton = styled.div<{
  isSubmitting: boolean;
  dangerButton: boolean;
}>`
  button {
    ${typoInputFont};

    position: relative;
    padding: 13px 16px; //13px to have the same height as a text input
    color: ${cssVars.colors.white};

    border: none;
    border-radius: ${cssVars.borderRadius.default};

    ${(props) =>
      props.dangerButton
        ? css`
            background: ${cssVars.colors.red};
          `
        : css`
            background: ${cssVars.colors.green};
          `};

    overflow: hidden;
    transition:
      padding-right 0.3s ease 0s,
      background 0.5s ease-in-out;

    .submitIcon {
      display: none;

      width: 40px;
      height: 20px;

      float: right;
      animation: dotWrapperAnim 2s ease normal infinite;

      > div {
        display: inline-block;
        width: 8px;
        height: 8px;

        background: #fff;

        border-radius: 50%;

        animation:
          loadingDotAnim 1s ease normal infinite,
          loadingDotAnimColor 2s ease normal infinite;

        &:nth-child(2) {
          animation-delay: 0.1s, 0s;
        }

        &:nth-child(3) {
          animation-delay: 0.2s, 0s;
        }

        &:nth-child(4) {
          animation-delay: 0.3s, 0s;
        }

        &:nth-child(5) {
          animation-delay: 0.4s, 0s;
        }
      }
    }

    &:hover {
      cursor: pointer;
      ${(props) =>
        props.dangerButton
          ? css`
              background: ${cssVars.colors.orange};
            `
          : css`
              background: ${cssVars.colors.greenLight};
            `};
    }
    ${(props) =>
      props.isSubmitting &&
      css`
        background: ${cssVars.colors.greenLight};

        &:hover {
          cursor: wait;
        }

        .submitIcon {
          display: inline-block;
          margin-left: 10px;
        }
      `}
  }

  @keyframes loadingDotAnim {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes loadingDotAnimColor {
    0% {
      background: ${cssVars.colors.blueLight};
    }
    49.99% {
      background: ${cssVars.colors.blueLight};
    }
    50% {
      background: ${cssVars.colors.green};
    }
    100% {
      background: ${cssVars.colors.green};
    }
  }

  @keyframes dotWrapperAnim {
    0% {
      transform: scaleX(1);
    }
    49.99% {
      transform: scaleX(1);
    }
    50% {
      transform: scaleX(-1);
    }
    100% {
      transform: scaleX(-1);
    }
  }
`;

export default StyledButton;
