import styled, { css } from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import StyledIcon from "../Icon/Icon.styles.js";

type InfoBlockStylesProps = {
  type: string;
  isForm?: boolean;
};

const StyledInfoBlock = styled.div<InfoBlockStylesProps>`
  padding: ${cssVars.distances.paddingBox};

  color: ${cssVars.colors.white};

  ${(props) =>
    props.isForm &&
    css`
      max-width: ${cssVars.distances.formWidth};
    `}

  ${(props) =>
    props.type == "confirm" &&
    css`
      background: ${cssVars.colors.green};
    `}

  ${(props) =>
    props.type == "warning" &&
    css`
      background: ${cssVars.colors.yellow};
      ${StyledIcon} {
        svg {
          fill: ${cssVars.colors.white};
        }
      }
    `}

  ${(props) =>
    props.type == "error" &&
    css`
      background: ${cssVars.colors.red};
      ${StyledIcon} {
        svg path {
          fill: ${cssVars.colors.white};
        }
      }
    `}
  
  font-size: 14px;
  line-height: 22px;

  ${StyledIcon} {
    display: inline-block;
    margin-right: 8px;
  }

  a {
    color: ${cssVars.colors.white};
  }
`;

export default StyledInfoBlock;
