import { QueryClient, useQuery, UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  GateData,
  VisitorAverages,
  VisitorCount,
} from "../../api/v1/ReportData/index.js";
import { GateInfo } from "../../api/v1/ReportInfo/index.js";
import type { ExtendedQueryResult, SpaceOptions } from "./base.js";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type { DataMap } from "./multi.js";
import { multiPlaceholder, queryFnMulti, queryFnSingle } from "./multi.js";

interface SpaceDataOptions<Result> extends SpaceOptions<Result> {
  queryClient: QueryClient;
}

interface SpaceGateDataOptions<Result> extends SpaceDataOptions<Result> {
  pseudogateId: string;
}

interface SpaceGateVisitorCountDataOptions<Result>
  extends SpaceDataOptions<Result> {
  from: string;
  till: string;
  interval?: "Daily" | "Weekly";
}

export function useSpacePseudogates({
  spaceId,
  enabled: enabledOption,
  placeholderData,
}: SpaceOptions<GateInfo[]>): UseQueryResult<GateInfo[]> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = ["Spaces", spaceId, "Pseudogates"];
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<GateInfo[]>,
    placeholderData,
    staleTime: staleTimeInfo,
  });
}

export function useSpacePseudogateLatestData({
  spaceId,
  pseudogateId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceGateDataOptions<GateData>): ExtendedQueryResult<GateData> {
  const enabled =
    (enabledOption === undefined || enabledOption) &&
    !!spaceId &&
    !!pseudogateId;
  const queryKey = useMemo(
    () => ["Spaces", spaceId, "Pseudogates", pseudogateId, "LatestData"],
    [spaceId, pseudogateId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnSingle<GateData>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}

export function useSpacePseudogatesLatestData({
  spaceId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceDataOptions<DataMap<GateData>>): ExtendedQueryResult<
  DataMap<GateData>
> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = useMemo(
    () => ["Spaces", spaceId, "Pseudogates", multiPlaceholder, "LatestData"],
    [spaceId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnMulti<GateData>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}

export function useSpacePseudoVisitorCount({
  spaceId,
  from,
  till,
  interval = "Weekly",
  enabled: enabledOption,
  queryClient,
}: SpaceGateVisitorCountDataOptions<VisitorCount[]>): ExtendedQueryResult<
  VisitorCount[]
> {
  const enabled =
    (enabledOption === undefined || enabledOption) &&
    !!spaceId &&
    !!from &&
    !!till;

  const queryKey = useMemo(
    () => [
      "Spaces",
      spaceId,
      "Pseudogates",
      multiPlaceholder,
      "Summary",
      interval,
      from,
      till,
    ],
    [spaceId, from, till],
  );

  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnSingle<VisitorCount>,
      meta: { queryClient },
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}

export function useSpacePseudogateVisitorAverages({
  spaceId,
  pseudogateId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceGateDataOptions<DataMap<VisitorAverages>>): ExtendedQueryResult<
  DataMap<VisitorAverages>
> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = useMemo(
    () => ["Spaces", spaceId, "Pseudogates", pseudogateId, "Average"],
    [spaceId, pseudogateId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnMulti<VisitorAverages>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}
