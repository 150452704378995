import { Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.js";
import TextInput from "../../UI/TextInput/TextInput.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import InfoBlock from "../../atoms/InfoBlock/InfoBlock.js";
import Logo from "../../atoms/Logo/Logo.js";
import StyledPasswordForgotten from "./PasswordForgotten.styles.js";

type PasswordForgottenProps = {
    submit: boolean;
    error?: string;
};

const PasswordForgotten: React.FC<PasswordForgottenProps> = ({
    submit,
    error = "",
}: PasswordForgottenProps) => {
    // const [submit, setSubmit] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <StyledPasswordForgotten>
            <BlockWrapper>
                <Form>
                    <Logo />
                    <h1>{t("auth.forgotPassword.title")}</h1>
                    <p>{t("auth.forgotPassword.body")}</p>
                    <TextInput
                        name="email"
                        type="email"
                        placeholder={t("auth.login.email")}
                        label={t("auth.login.email")}
                        autocomplete="username"
                    />
                    <Button
                        isSubmitting={submit}
                        label={t("auth.forgotPassword.submit")}
                        submitButton={true}
                    />
                </Form>
            </BlockWrapper>
            {error && <InfoBlock body={error} type="error" />}
        </StyledPasswordForgotten>
    );
};

export default PasswordForgotten;
