import { down } from "styled-breakpoints";
import styled from "styled-components";
import { Col, Row } from "../../../styles/Grid.js";
import StyledFormWrapper from "../FormWrapper/FormWrapper.styles.js";

const StyledFormColumns = styled.div`
  width: 700px;
  margin: 30px auto 50px;

  > ${Row} > ${Col} {
    &:first-child {
      ${StyledFormWrapper} {
        float: right;
      }
    }

    &:last-child {
      ${StyledFormWrapper} {
        float: left;
        margin-left: 20px;
      }
    }
  }

  ${StyledFormWrapper} {
    width: 100%;
  }

  ${down("md")} {
    width: 100%;

    > ${Row} > ${Col} {
      &:first-child,
      &:last-child {
        ${StyledFormWrapper} {
          float: none;
          width: 100%;
          margin-left: auto;
        }
      }
    }
  }
`;

export default StyledFormColumns;
