import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DAYS_FULL, DAYS_SHORT } from "../constants";
import { usePeakPresenceData } from "./api.js";
type Props = {
  spaceId: string;
  startOfWeekDate: string;
};

type Retval = {
  x: string[];
  y: string[];
  z: number[][];
  maxZ: number;
};
export default function usePeakPresence({
  spaceId,
  startOfWeekDate,
}: Props): Retval {
  // I hate this, and we probably shouldn't do it this way, but I'll leave it here for now

  const { data } = usePeakPresenceData({
    spaceId,
    date: new Date(startOfWeekDate).getTime(),
  });

  const { t } = useTranslation();
  const x = DAYS_SHORT.map((day) => t(`dateValues.days.short.${day}`));
  const y = Array.from({ length: 24 }, (_, i) => i.toString() + ":00");

  const { z, maxZ } = useMemo(() => {
    const z: number[][] = Array.from({ length: 24 }, () =>
      Array.from({ length: 7 }, () => 0),
    );
    if (!data)
      return {
        z,
        maxZ: 0,
      };

    const maxZ = Object.values(data)
      .map((subdata) =>
        Object.values(subdata).reduce((a, b) => (a > b ? a : b), 0),
      )
      .reduce((a, b) => (a > b ? a : b), 0);

    DAYS_FULL.forEach((dayname, dayindex) => {
      const day: Record<string, number> = data[dayname];

      if (!day) return;

      Object.entries(day).forEach(([hour, value]) => {
        z[hour][dayindex] = value;
      });
    });

    return { z, maxZ };
  }, [data]);

  return {
    x,
    y,
    z,
    maxZ,
  };
}
