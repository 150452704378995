import styled from "styled-components";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";
import StyledDropdown from "../Dropdown/Dropdown.styles.js";

const StyledDateSliderWeek = styled.div`
  ${StyledBlockWrapper} {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${StyledDropdown} {
    display: inline-block;
    width: 176px;

    margin-left: 10px;
  }

  .text {
    display: inline-block;

    padding: 13px 20px;
  }
`;

export default StyledDateSliderWeek;
