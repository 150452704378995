import styled from "styled-components";
import type { OccupationState } from "../../../constants.js";
import cssVars from "../../../styles/cssVars.js";
import StyledIcon from "../Icon/Icon.styles.js";

type Props = {
  state: OccupationState;
};

const StyledDistanceOkCircle = styled.div<Props>`
  > div {
    position: relative;

    width: 346px;
    height: 346px;

    border-radius: 50%;

    &.ok {
      background: ${cssVars.colors.green};
    }

    &.warning {
      background: ${cssVars.colors.orange};
    }

    &.danger {
      background: ${cssVars.colors.red};
    }
  }

  .dangerStroke {
    position: absolute;
    top: 151px;
    left: 60px;

    width: 230px;
    height: 50px;

    background: #fff;
    border-radius: 25px;

    opacity: 0;
  }

  ${StyledIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      fill: #fff;
    }
  }
`;

export default StyledDistanceOkCircle;
