import { Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.js";
import PasswordInput from "../../UI/PasswordInput/PasswordInput.js";
import TextInput from "../../UI/TextInput/TextInput.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import InfoBlock from "../../atoms/InfoBlock/InfoBlock.js";
import Logo from "../../atoms/Logo/Logo.js";
import StyledPasswordReset from "./PasswordReset.styles.js";

type PasswordResetProps = {
    submit: boolean;
    error?: string;
};

const PasswordReset: React.FC<PasswordResetProps> = ({
    submit,
    error = "",
}: PasswordResetProps) => {
    const { t } = useTranslation();

    return (
        <StyledPasswordReset>
            <BlockWrapper>
                <Form>
                    <Logo />
                    <h1>{t("auth.resetPassword.title")}</h1>
                    <p>{t("auth.resetPassword.resetPasswordBody")}</p>
                    {/*<TextInput*/}
                    {/*  name="password"*/}
                    {/*  placeholder={t('auth.resetPassword.password')}*/}
                    {/*  label={t('auth.resetPassword.password')}*/}
                    {/*  type="password"*/}
                    {/*/>*/}
                    <PasswordInput
                        name="password"
                        placeholder={t("auth.resetPassword.password")}
                        label={t("auth.resetPassword.password")}
                        autocomplete="new-password"
                    />
                    <TextInput
                        name="password_repeat"
                        placeholder={t("auth.resetPassword.password")}
                        label={t("auth.resetPassword.passwordConfirmed")}
                        type="password"
                        autocomplete="new-password"
                    />
                    <Button
                        isSubmitting={submit}
                        label={t("auth.resetPassword.submit")}
                        submitButton={true}
                    />
                </Form>
            </BlockWrapper>
            {error && <InfoBlock type="error" body={error} />}
        </StyledPasswordReset>
    );
};

export default PasswordReset;
