import { hour, minute } from "msecs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SpaceData } from "../../../api/v1/Space/space.js";
import {
  OCCUPATION_STATES,
  OccupationState,
  UserRole,
} from "../../../constants.js";
import usePeakOccupancy from "../../../hooks/usePeakOccupancy.js";
import AuthService from "../../../services/AuthService.js";
import { Col, Row } from "../../../styles/Grid.js";
import {
  formatHourMinute,
  parseHourMinute,
  startOfWeek,
} from "../../../utils/date-time.js";
import { maximumReducer, minimumReducer } from "../../../utils/math.js";
import useWebSocketData from "../../../websockets/useWebSocketData.js";
import { LocationOccupationData } from "../../../websockets/websockets.js";
import DeviceList from "../../Dashboard/DeviceList/DeviceList.js";
import HourlyPresence from "../../Dashboard/HourlyPresence/HourlyPresence.js";
import LiveEvents from "../../Dashboard/LiveEvents/LiveEvents.js";
import LiveEventsCumulative from "../../Dashboard/LiveEventsCumulative/LiveEventsCumulative.js";
import MiniInfoCardCapacity from "../../Dashboard/MiniInfoCardCapacity/MiniInfoCardCapacity.js";
import PopularTimes from "../../Dashboard/PopularTimes/PopularTimes.js";
import Button from "../../UI/Button/Button.js";
import DateSliderWeek from "../../UI/DateSliderWeek/DateSliderWeek.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import ValueBlock from "../../atoms/ValueBlock/ValueBlock.js";
import Header from "../../portal/Header/Header.js";
import StyledDashboard from "./Dashboard.styles.js";

type DashboardProps = {
  id: string;
  name: string;
  type?: string;
  data: SpaceData;
  deleteAction?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Dashboard: React.FC<DashboardProps> = ({
  id,
  name,
  type,
  data,
  deleteAction,
}: DashboardProps) => {
  const { t } = useTranslation();

  const canEdit = AuthService.hasRole(UserRole.Admin);

  const [startOfWeekDate, setStartOfWeekDate] = useState<string>(null);

  const minimumOpeningClosingTime = 0 * hour;
  const maximumOpeningClosingTime = 24 * hour - 1 * minute;

  let openingTime = data.opening_hours
    .map((oh) => oh.opening_time)
    .map(parseHourMinute)
    // map(ts => ts - timeZoneOffset).
    .reduce(minimumReducer, maximumOpeningClosingTime);
  if (openingTime === maximumOpeningClosingTime) {
    openingTime = minimumOpeningClosingTime;
  }
  // openingTime -= timeZoneOffset;
  // openingTime = Math.max(minimumOpeningClosingTime, openingTime);

  let closingTime = data.opening_hours
    .map((oh) => oh.closing_time)
    .map(parseHourMinute)
    // map(ts => ts - timeZoneOffset).
    .reduce(maximumReducer, minimumOpeningClosingTime);
  if (closingTime === minimumOpeningClosingTime) {
    closingTime = maximumOpeningClosingTime;
  }
  // closingTime -= timeZoneOffset;
  // closingTime = Math.min(maximumOpeningClosingTime, closingTime);

  const openingTimeFormatted = formatHourMinute(openingTime);
  const closingTimeFormatted = formatHourMinute(closingTime);

  const methodName = `lococc-${id}`;
  const wsData = useWebSocketData<LocationOccupationData>(methodName);

  const counterState = OCCUPATION_STATES[wsData?.state] ?? OccupationState.Ok;
  const counterNumber = wsData?.curOccupation ?? 0;
  const counterMaxNumber = wsData?.maxOccupation ?? data.capacity;

  const updateHourlyPresence = (dateTime: Date) => {
    setStartOfWeekDate(startOfWeek(dateTime).toISOString());
  };

  const peakOccupancyData = usePeakOccupancy({
    locationId: id,
    startTime: openingTimeFormatted,
    endTime: closingTimeFormatted,
    startOfWeekDate: startOfWeekDate,
  });

  return (
    <>
      <Header title={`${t("locations.dashboard.header.title")} - ${name}`}>
        {/*<Button label={t('locations.dashboard.header.buttonDelete')} dangerButton={true} onClick={deleteAction} />*/}
        {canEdit && (
          <Button
            label={t("locations.dashboard.header.button")}
            url={"/locations/" + id + "/edit"}
          />
        )}
      </Header>
      <StyledDashboard>
        <Row>
          <Col cols={{ lg: 1, xl: 9 / 12 }} className="infoCards pushRight">
            <Row>
              <Col cols={{ lg: 1 }} className="locationInfoBlock">
                {/*<FloorOverview />*/}
                <BlockWrapper>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      columnGap: "32px",
                    }}
                  >
                    <ValueBlock
                      label={t("locations.form.capacity")}
                      value={data.capacity}
                    />
                    <ValueBlock
                      label={t("locations.form.areaSize")}
                      value={data.area_size}
                    />
                    <ValueBlock
                      label={t("locations.form.openingTime")}
                      value={openingTimeFormatted}
                    />
                    <ValueBlock
                      label={t("locations.form.closingTime")}
                      value={closingTimeFormatted}
                    />
                  </div>
                </BlockWrapper>
              </Col>
            </Row>
            <Row>
              <Col cols={{ lg: 1, xl: 8 / 12 }} className="infoCards pushRight">
                <DateSliderWeek onChange={updateHourlyPresence} />
                <HourlyPresence data={peakOccupancyData} />
                {/*<SocialDistanceWide*/}
                {/*  count={counterNumber}*/}
                {/*  maxCount={counterMaxNumber}*/}
                {/*  state={counterState}*/}
                {/*  hideText={true}*/}
                {/*/>*/}
                {/*{type?.toLowerCase() == 'building' ? (*/}
                {/*  <FloorOverview />*/}
                {/*) : (*/}
                {/*  <BigCountWide count={30} maxCount={100} state="ok" hideText={true} />*/}
                {/*)}*/}
              </Col>
              <Col cols={{ lg: 1, xl: 4 / 12 }} className="infoCards">
                <PopularTimes
                  locationId={id}
                  startTime={openingTimeFormatted}
                  endTime={closingTimeFormatted}
                />
                <MiniInfoCardCapacity
                  count={counterNumber}
                  maxCount={counterMaxNumber}
                  state={counterState}
                />
                <DeviceList initialDevices={data.devices} />
                {/*<MiniInfoCardCapacity count={60} maxCount={100} state="warning" />*/}
                {/*<MiniInfoCardCapacity count={100} maxCount={100} state="danger" />*/}
              </Col>
            </Row>
          </Col>

          <Col cols={{ lg: 1, xl: 3 / 12 }} className="infoCards">
            <BlockWrapper>
              <Row>
                <Col cols={{ md: 1 }}>
                  <h2>{t("locations.dashboard.header.liveEvents")}</h2>
                </Col>
              </Row>
            </BlockWrapper>
            <LiveEvents targetCapacity={10} locationIdentity={id} />
            <LiveEventsCumulative locationIdentity={id} />
          </Col>
        </Row>
      </StyledDashboard>
    </>
  );
};

export default Dashboard;
