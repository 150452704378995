import styled from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import StyledIcon from "../../atoms/Icon/Icon.styles.js";

const StyledLogoutButton = styled.div`
  ${StyledIcon} {
    &:hover {
      cursor: pointer;

      svg {
        path {
          transition: fill 0.5s ease-in-out;
          fill: ${cssVars.colors.red};
        }
      }
    }
  }
`;

export default StyledLogoutButton;
