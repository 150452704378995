import { down } from "styled-breakpoints";
import styled from "styled-components";
import StyledLogo from "../../atoms/Logo/Logo.styles.js";

const StyledMessagePage = styled.div`
  max-width: 800px;

  margin: 50px auto;

  text-align: center;

  p {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
  }

  ${StyledLogo} {
    margin-bottom: 80px;
  }

  ${down("xm")} {
    ${StyledLogo} {
      margin-bottom: 20px;
    }
  }
`;

export default StyledMessagePage;
