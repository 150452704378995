import styled from "styled-components";
import { boxShadow } from "../../../styles/boxShadow.js";
import cssVars from "../../../styles/cssVars.js";

const StyledBlockWrapper = styled.div`
  padding: 16px;

  background: ${cssVars.colors.white};

  border-radius: ${cssVars.borderRadius.default};

  ${boxShadow(2, 2)};

  form > div {
    margin-bottom: 10px;
  }
`;

export default StyledBlockWrapper;
