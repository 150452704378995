import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOMClient from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  matchRoutes,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { API_ENDPOINT } from "./constants.js";
import AuthorizedLayout from "./layouts/AuthorizedLayout.js";
import UnauthorizedLayout from "./layouts/UnauthorizedLayout.js";
import { allRoutes, authenticatedRoutes } from "./routes.js";
import { theme } from "./styles/breakpoints.js";
import "./styles/main.css";
import "./utils/i18n";

const AuthorizedSwitch: React.FC = () => {
  const location = useLocation(); //do not remove, otherwise page animations break
  const element = useRoutes(allRoutes);
  // const element = useRoutes(unauthenticatedRoutes);
  // const element2 = useRoutes(authenticatedRoutes);

  // console.log(element2);
  console.log(`API URL:`, API_ENDPOINT);

  const isAuthPath = (path) => {
    return matchRoutes(authenticatedRoutes, path);
  };

  if (isAuthPath(location)) {
    return <AuthorizedLayout>{element}</AuthorizedLayout>;
  }

  return (
    <>
      <UnauthorizedLayout>{element}</UnauthorizedLayout>
      {/*<AuthorizedLayout>*/}
      {/*  <Routes location={location} key={location.pathname}>*/}
      {/*    {element2}*/}
      {/*  </Routes>*/}
      {/*</AuthorizedLayout>*/}
    </>
  );
};

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

const queryClient = new QueryClient();

root.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<AuthorizedSwitch />} />
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>,
);
