import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { PsLoginData } from "../api/v1/Auth/psLogin";
import AuthService from "../services/AuthService";
import RESTService from "../services/RESTService";

function queryFn({ queryKey }): Promise<PsLoginData> {
  return new Promise<PsLoginData>((resolve, reject) => {
    RESTService.post({
      url: queryKey.join('/'),
      payload: undefined,
      errorCallback(err: AxiosError<PsLoginData>): void {
        console.error("Failed to log in", err);
        return reject(err);
      },
      successCallback(res: AxiosResponse<PsLoginData>) {
        if(!AuthService.setAuthTokenMaybe(res.data.token))
            console.log("Did not replace token, as we are logged in as a user");
        return resolve(res.data);
      },
    });
  });
}

const staleTime = 60 * 60 * 1000;
const select = (data) => data.token;

export function usePsLogin(
    token: string | undefined | null
): UseQueryResult<PsLoginData> {
  return useQuery({
    enabled: !!token,
    staleTime,
    queryKey: ["Auth", "pslogin", token],
    queryFn,
  });
}

export default function useJwt(
    token: string | undefined | null
): UseQueryResult<string> {
  return useQuery({
    enabled: !!token,
    staleTime,
    queryKey: ["Auth", "pslogin", token],
    queryFn,
    select,
  });
}
