import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn } from "./base.js";
import { PopularTimesData } from "../../api/v1/ReportData/index.js";

interface Options {
    spaceId: string;
}

export function usePopularTimesData({
    spaceId,
}: Options): UseQueryResult<PopularTimesData> {
    const queryKey = ["Spaces", spaceId, "populartimes"];
    const enabled = !!spaceId;
    const staleTime = 24 * 60 * 60 * 1000;
    return useQuery({
        enabled,
        queryKey,
        queryFn: baseQueryFn<PopularTimesData>,
        staleTime,
    });
}
