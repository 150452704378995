import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledConfirmActionModal from "./ConfirmActionModal.styles.js";

type ConfirmActionModalProps = {
    title: string;
    message: string;
    onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onDecline: (event: React.MouseEvent<HTMLButtonElement>) => void;
    show: boolean;
};

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
    title,
    message,
    onConfirm,
    onDecline,
    show,
}: ConfirmActionModalProps) => {
    const { t } = useTranslation();

    if (!show) {
        return <></>;
    }

    return (
        <StyledConfirmActionModal>
            <div className="background"></div>

            <BlockWrapper>
                <h1>{title}</h1>
                <p>{message}</p>
                <div className="buttonWrapper">
                    <Button
                        label={t("confirmModal.buttonNo")}
                        dangerButton={true}
                        onClick={onDecline}
                    />
                    <Button
                        label={t("confirmModal.buttonYes")}
                        onClick={onConfirm}
                    />
                </div>
            </BlockWrapper>
        </StyledConfirmActionModal>
    );
};

export default ConfirmActionModal;
