import {
  API_ENDPOINT,
  AUTH_TOKEN,
  REFRESH_PERIOD,
  REFRESH_TOKEN,
  ASPNETCORE_ROLE,
  UserRole,
} from "../constants.js";

import axios, { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { LoginData } from "../pages/Auth/auth.js";

class AuthService {
  static setAuthToken(token?: string) {
    if (!token) {
      localStorage.removeItem(AUTH_TOKEN);
    } else {
      localStorage.setItem(AUTH_TOKEN, token);
    }
  }

  static setRefreshToken(token?: string) {
    if (!token) {
      localStorage.removeItem(REFRESH_TOKEN);
    } else {
      localStorage.setItem(REFRESH_TOKEN, token);
    }
  }

  static invalidateToken() {
    localStorage.removeItem(AUTH_TOKEN);
  }

  static getAuthToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN);
  }

  static getAuthTokenEmail(): string | null {
    const token = AuthService.getAuthToken();

    if (!token) return;

    const { email } = jwtDecode<{ email: string }>(token);

    return email;
  }

  static getAuthTokenRoles(): string[] | null {
    const token = AuthService.getAuthToken();

    if (!token) return;

    return getRolesFromToken(token);
  }

  static hasRole(role: UserRole): boolean {
    const roles = AuthService.getAuthTokenRoles();
    if (!roles) return false;
    return roles.includes(role);
  }

  static checkToken() {
    return new Promise((resolve, reject) => {
      const token = AuthService.getAuthToken();

      // No token means no authentication
      if (!token) {
        return reject(new Error("No token available"));
      }

      // Compare timestamps
      const { exp: expiresAt, nbf: notValidBefore } = jwtDecode<{
        exp: number;
        nbf: number;
      }>(token);
      const currentTime = Math.floor(Number(new Date()) / 1000);

      // Reject if the token is not yet valid. Add a five second grace period
      // TODO This check is causing Martijn to have an unreliable "login experience".
      // TODO The token's "Not valid before" claim seems to be in the future.
      if (notValidBefore > currentTime + 5) {
        console.log(`JWT token is not yet valid ${currentTime} < ${notValidBefore}`);
        // return reject(new Error('JWT token is not yet valid'));
      }

      // Reject if the token has expired
      if (currentTime > expiresAt) {
        this.invalidateToken();
        return reject(new Error("Token has expired"));
      }

      // Do a timely refresh of the token if and when necessary
      if (currentTime + REFRESH_PERIOD >= expiresAt) {
        return axios
          .post<LoginData>(
            API_ENDPOINT + "Auth/RefreshToken",
            {
              access_token: localStorage.getItem(AUTH_TOKEN),
              refresh_token: localStorage.getItem(REFRESH_TOKEN),
            },
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            },
          )
          .then((res: AxiosResponse<LoginData>) => {
            if (res.status != 200) {
              return;
            }

            axios.defaults.headers.common = {
              Authorization: `bearer ${res.data.access_token}`,
            };
            AuthService.setAuthToken(res.data.access_token);
            return resolve(res.data.access_token);
          });
      }

      return resolve(token);
    });
  }

  static checkIsUser(): Promise<void> {
    return AuthService.checkHasRole(UserRole.User);
  }

  static checkHasRole(role: UserRole): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if (AuthService.hasRole(role))
            resolve();
        else
            reject();
    });
  }

  static setAuthTokenMaybe(token: string): boolean {
    const currentToken = AuthService.getAuthToken();

    if (currentToken) {
        // Only consider not using the new token if we have one
        const currentRoles = getRolesFromToken(currentToken);
        const newRoles = getRolesFromToken(token);

        if (currentRoles && currentRoles.length) {
            // The old token must have roles to keep considering
            if (!newRoles || newRoles.length == 0)
                // New token doesn't have roles, that's good enough
                return false;

            // Set exists, but does not yet provide useful utility methods (difference, intersection, and such)
            const oldDiff = currentRoles.filter((role) => !newRoles.includes(role));
            const newDiff = newRoles.filter((role) => !currentRoles.includes(role));
            if (oldDiff.length &&
                (
                    newDiff.length == 0 ||
                    (newDiff.length == 1 && newDiff[0] == UserRole.PublicScreen)
                )
            ) {
                // Old token has more roles, and the new one only has publicscreen as possible extra
                return false;
            }
        }
    }
    AuthService.setAuthToken(token);
    return true;
  }
}

function getRolesFromToken(token: string): string[] {
    const decoded = jwtDecode<object>(token);

    if (!decoded.hasOwnProperty(ASPNETCORE_ROLE))
        return [];

    const value: string[] | string = decoded[ASPNETCORE_ROLE];
    if (typeof value == 'string')
        return [value];
    else
        return value;
}

export default AuthService;
