import { motion } from "framer-motion";
import React from "react";
import { OccupationState } from "../../../constants.js";
import Icon from "../Icon/Icon.js";
import StyledDistanceBlock from "./DistanceBlock.styles.js";

type DistanceBlockProps = {
    state: OccupationState;
};

const DistanceBlock: React.FC<DistanceBlockProps> = ({
    state,
}: DistanceBlockProps) => {
    if (state === OccupationState.Ok) {
        return (
            <StyledDistanceBlock state={state}>
                <div className="ok">
                    <Icon name="check" />
                </div>
            </StyledDistanceBlock>
        );
    } else if (state === OccupationState.Warning) {
        return (
            <StyledDistanceBlock state={state}>
                <motion.div
                    className="warning"
                    animate={{ scale: [0.8, 1, 0.8, 0.8] }}
                    // transition={{ duration: 2, ease: 2, repeat: Infinity }}>
                    transition={{
                        type: "spring",
                        bounce: 0.5,
                        duration: 1.5,
                        repeat: Infinity,
                    }}
                >
                    <Icon name="exclamation" size={200} />
                </motion.div>
            </StyledDistanceBlock>
        );
    } else if (state === OccupationState.Danger) {
        const spring = {
            type: "spring",
            // damping: 10,
            // stiffness: 100,
            // scale: [0, 200, 400, 600, 800, 1000],
            // ease: 'easeInOut',
            repeatDelay: 1,
            duration: 1,
            delayChildren: 1,
            repeat: Infinity,
            when: "beforeChildren",
        };

        const springBar = {
            repeat: Infinity,
            scale: {
                times: [0, 0.3, 0.35, 0.4],
                repeatDelay: 1,
                delay: 1,
                duration: 1,
                repeat: Infinity,
            },
            opacity: {
                times: [0, 0.1, 0.98, 0.99],
                repeatDelay: 1,
                delay: 1,
                duration: 1,
                repeat: Infinity,
            },
        };

        return (
            <StyledDistanceBlock state={state}>
                <motion.div
                    className="danger"
                    transition={spring}
                    animate={{
                        scale: [0, 1.1, 0.85, 1.05, 0.9, 1.025, 0.95, 1.0],
                    }}
                >
                    {/*<div className="danger">*/}
                    <motion.div
                        className="dangerStroke"
                        transition={springBar}
                        initial={{ opacity: 0 }}
                        animate={{
                            scale: [1.2, 1, 1.02, 1],
                            opacity: [0, 1, 1, 0],
                        }}
                    ></motion.div>
                    {/*animate={{ opacity: [0, 1, 0] }}></motion.div>*/}
                    {/*</div>*/}
                </motion.div>

                {/*<motion.div className="danger" transition={spring} animate={{ scale: [0, 1.3, 0.7, 1.2, 0.8, 1.1, 0.9, 1.0] }}>*/}
                {/*  <Icon name="check" />*/}
                {/*</motion.div>*/}
            </StyledDistanceBlock>
        );
    }

    return <></>;
    // <StyledDistanceBlock></StyledDistanceBlock>;
};

export default DistanceBlock;
