import styled from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import getSVGUrl from "../../../styles/helpers/getSVG.js";

const StyledCheckbox = styled.div`
  display: inline-block;

  min-height: 26px;
  line-height: 26px;

  margin-right: 10px;

  input[type="checkbox"] {
    display: none;
  }

  label {
    position: relative;
    display: inline-block;

    min-height: 26px;
    padding-left: 40px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;

      left: 0;
      top: 0;

      width: 22px;
      height: 22px;

      border: 2px solid ${cssVars.colors.grayDark};
      border-radius: ${cssVars.borderRadius.default};

      transition: background-color ${cssVars.transitionSpeeds.medium}
        ease-in-out;
    }
  }

  input[type="checkbox"]:checked + label:before {
    background: ${getSVGUrl("checksmall")};
    background-repeat: no-repeat;
    background-position: 4px 5px;

    background-color: ${cssVars.colors.green};
    border-color: ${cssVars.colors.green};
  }
`;

export default StyledCheckbox;
