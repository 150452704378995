import styled from "styled-components";

const StyledSmallCircleImage = styled.div`
  img {
    width: 48px;
    height: 48px;

    border-radius: 50%;

    object-fit: cover;
  }
`;

export default StyledSmallCircleImage;
