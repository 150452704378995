import { object, string } from "yup";
import { getCurrentLanguageTranslation } from "../../../locales/helpers/index.js";
import PasswordService from "../../../services/PasswordService.js";

export const defaults = {
    email: "",
    password: "",
    confirm_password: "",
};

const { validationRules: msg } = getCurrentLanguageTranslation();

export const validation = object().shape({
    organization_name: string()
        .min(3, msg.string.min)
        .required(msg.mixed.required),
    email: string().email(msg.string.email).required(msg.mixed.required),
    password: PasswordService.validatedPasswordRules(msg, false),
    confirm_password: PasswordService.validatedPasswordRules(msg, true),
});
