import { Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.js";
import TextInput from "../../UI/TextInput/TextInput.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import InfoBlock from "../../atoms/InfoBlock/InfoBlock.js";
import Logo from "../../atoms/Logo/Logo.js";
import StyledLogin from "./Login.styles.js";

type LoginProps = {
    submit: boolean;
    error?: string;
    emailConfirmed?: boolean;
};

const Login: React.FC<LoginProps> = ({
    submit,
    error = "",
    emailConfirmed = false,
}: LoginProps) => {
    const { t } = useTranslation();

    return (
        <StyledLogin>
            <BlockWrapper>
                <Form>
                    <Logo />
                    <h1>{t("auth.login.header")}</h1>
                    <TextInput
                        name="email"
                        type="email"
                        id="username"
                        placeholder={t("auth.login.email")}
                        label={t("auth.login.email")}
                        autocomplete="username"
                    />
                    <TextInput
                        name="password"
                        id="password"
                        placeholder={t("auth.login.password")}
                        label={t("auth.login.password")}
                        type="password"
                        autocomplete="current-password"
                    />

                    <Button
                        isSubmitting={submit}
                        label={t("auth.login.submit")}
                        submitButton={true}
                    />
                </Form>
            </BlockWrapper>

            {error && <InfoBlock type="error" body={error} />}
            {emailConfirmed && (
                <InfoBlock
                    type="confirm"
                    body={t("auth.login.emailVerification")}
                />
            )}
        </StyledLogin>
    );
};

export default Login;
