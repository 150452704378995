import { AreaChart as TremorAreaChart } from "@/components/AreaChart";

export default function AreaChart({
  title,
  desc,
  index = "name",
  type = "default",
  categories,
  data,
}: {
  title?: string;
  desc?: string;
  index?: string;
  type?: "default" | "stacked" | "percent";
  categories: string[];
  data: ({ [key: string]: number } & {
    name: string;
  })[];
}) {
  return (
    <section className="p-4 shadow-box rounded-md bg-white">
      {title && (
        <header className="space-y-1">
          <h2 className="text-2xl font-medium !mb-0">{title}</h2>
          {desc && <p className="text-sm text-gray-500">{desc}</p>}
        </header>
      )}

      <TremorAreaChart
        index={index}
        type={type}
        data={data}
        categories={categories}
        onValueChange={(v) => console.log(v)}
        colors={["green", "orange", "blue", "cyan"]}
        className="h-80"
      />
    </section>
  );
}
