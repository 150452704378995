import React from "react";
import { Link } from "react-router-dom";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledLocationLinkCard from "./LocationLinkCard.styles.js";

type ScreenLinkCardProps = {
  discriminator: string;
};

const ScreenLinkCard: React.FC<ScreenLinkCardProps> = ({
  discriminator,
}: ScreenLinkCardProps) => {
  const origin = window.location.origin;

  if (!discriminator) return <></>;

  return (
    <StyledLocationLinkCard>
      <BlockWrapper>
        <Link
          to={`/screen/${discriminator}/dashboard`}
          target="_blank"
        >{`${origin}/screen/${discriminator}/dashboard`}</Link>
      </BlockWrapper>
    </StyledLocationLinkCard>
  );
};

export default ScreenLinkCard;
