import React, { useEffect, useState } from "react";

import { ErrorMessage, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import StyledErrorElement from "./ErrorElement.styles.js";

type ErrorElementProps = {
    name: string;
};

const ErrorElement: React.FC<ErrorElementProps> = ({
    name,
}: ErrorElementProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const formikContext = useFormikContext();

    useEffect(() => {
        if (Object.keys(formikContext.errors).includes(name)) {
            setError(true);
        } else {
            setError(false);
        }
    }, [formikContext.errors]);

    return (
        <StyledErrorElement error={error}>
            <ErrorMessage
                name={name}
                component="small"
                className={`text-danger ${name}`}
                render={(msg) => {
                    console.log(msg);
                    return t(msg);
                }}
            />
        </StyledErrorElement>
    );
};

export default ErrorElement;
