import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserRole } from "../../../constants.js";
import { useSpace } from "../../../hooks/api.js";
import Dashboard from "../../../components/pages/Dashboard/Dashboard.js";
import ConfirmActionModal from "../../../components/portal/ConfirmActionModal/ConfirmActionModal.js";
import AuthService from "../../../services/AuthService.js";
import RESTService from "../../../services/RESTService.js";

const nop = () => {
    /* NOP */
};

const LocationsDashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { data: locationData } = useSpace({
        spaceId: id,
    });
    const locationName = locationData?.name;
    const locationType = locationData?.type;

    const canDelete = AuthService.hasRole(UserRole.Admin);

    const deleteLocation = canDelete ? () => {
        void RESTService.delete<boolean>({
            url: `spaces/${id}`,
            successCallback(): void {
                history("/");
            },
        });
    } : nop;

    const showModal = canDelete ? () => {
        setShowDeleteModal(true);
    } : nop;

    const hideModal = canDelete ? () => {
        setShowDeleteModal(false);
    } : nop;

    if (!locationData) {
        return <></>;
    }

    return (
        <>
            <ConfirmActionModal
                title={t("confirmModal.defaultTitle")}
                message={t("confirmModal.defaultDeleteMessage")}
                onConfirm={deleteLocation}
                onDecline={hideModal}
                show={showDeleteModal}
            />

            <Dashboard
                id={id}
                name={locationName}
                type={locationType}
                data={locationData}
                deleteAction={showModal}
            />
        </>
    );
};

export default LocationsDashboardPage;
