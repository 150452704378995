import styled from "styled-components";

const StyledCenteredLink = styled.div`
  //a {
  display: block;
  text-align: center;

  margin-bottom: 20px;
  //}
`;

export default StyledCenteredLink;
