import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Plot from "react-plotly.js";
import { Col, Row } from "../../../styles/Grid.js";
import cssVars from "../../../styles/cssVars.js";
import { formatDateInput } from "../../../utils/date-time.js";
import useWebSocketSetter from "../../../websockets/useWebSocketSetter.js";
import { LiveEventData } from "../../../websockets/websockets.js";
import { useDailyOccupancyData } from "../../../hooks/api.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import ValueBlock from "../../atoms/ValueBlock/ValueBlock.js";
import StyledLiveEventsCumulative from "./LiveEventsCumulative.styles.js";

interface LiveEventsCumulativeProps {
    locationIdentity: string;
}

type ArrayPoint = {
    x: number;
    y: number;
};

const range = (count: number) => [...Array(count).keys()];

const LiveEventsCumulative: React.FC<LiveEventsCumulativeProps> = ({
    locationIdentity,
}: LiveEventsCumulativeProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const maxPoints = 288;

    const [pointArray, setPointArray] = useState<ArrayPoint[]>([]);
    const [utilization, setUtilization] = useState(0);
    const [max, setMax] = useState(0);
    const [avg, setAvg] = useState(0);

    const [latestTime, setLatestTime] = useState<number>(() => {
        const date = new Date();
        const minutes = Math.floor(date.getMinutes()/5)*5;
        date.setMinutes(minutes, 0, 0);
        return date.getTime();
    });
    const today = new Date().setHours(0,0,0,0); // setHours returns new timestamp as side-effect
    const [selectedDate, setSelectedDate] = useState(today);
    const isToday = selectedDate == today;
    const fiveMinutes = 5 * 60 * 1000;
    const nextTime = latestTime + fiveMinutes;

    const {
        data: dailyOccupationData,
        queryKey,
    } = useDailyOccupancyData({
        spaceId: locationIdentity,
        date: selectedDate,
    });

    const methodName = useMemo(() =>
        `liveevents-${locationIdentity}`
    , [locationIdentity]);
    const methodFunction = useCallback((dataPoint) => {
        if (dataPoint != null && isToday) {
            const y = Math.max(0, dataPoint.CurrentOccupancy);

            setPointArray((pointArray) => [
                ...pointArray.slice(0, pointArray.length-1),
                {
                    x: pointArray[pointArray.length-1]?.x ?? (new Date().getTime()),
                    y,
                },
            ]);
        }
    }, [isToday]);
    useWebSocketSetter<LiveEventData>(methodName, methodFunction);

    const copyLastDataPoint = useCallback((nextTime: number) => {
        if (isToday) { // This is probably excessive, but just to be sure
            setLatestTime(nextTime)
            setPointArray((pointArray) => [
                ...pointArray.slice(
                    pointArray.length < maxPoints ? 0 : 1,
                    maxPoints
                ), {
                    x: nextTime,
                    y: pointArray[pointArray.length-1]?.y ?? 0,
                },
            ]);
        }
    }, [isToday]);

    useEffect(() => {
        if (isToday) {
            const now = new Date().getTime();

            if (nextTime <= now) {
                copyLastDataPoint(nextTime);
            } else {
                const timeout = setTimeout(() => {
                    copyLastDataPoint(nextTime);
                }, nextTime - now);

                return () => clearTimeout(timeout);
            }
        }
    }, [isToday, nextTime, copyLastDataPoint]);

    useEffect(() => {
        const arr = pointArray;

        if (arr.length == 0) return;

        setUtilization(
            100 -
                (arr.filter((item: ArrayPoint) => item.y == 0).length /
                    arr.length) *
                    100,
        );
        setMax(Math.max(...arr.map((item: ArrayPoint) => item.y)));
        setAvg(
            arr.reduce((sum: number, itemA: ArrayPoint) => sum + itemA.y, 0) /
                arr.length,
        );
    }, [pointArray]);

    useEffect(() => {
        const FAKE_SAMPLES = 16;
        const date = new Date(latestTime);
        let arr: ArrayPoint[];
        if (!!dailyOccupationData && Object.entries(dailyOccupationData).length > 0) {
            arr = Object
                .entries(dailyOccupationData)
                .flatMap(([hourString, minmap]) => {
                    const hour = +hourString;
                    return Object.entries(minmap).map(([minuteString, value]) => {
                        const minute = +minuteString;
                        const val = +value;
                        const x = new Date(selectedDate).setHours(hour, minute); // setHour returns timestamp
                        const y = Math.max(val, 0);
                        return { x, y };
                    });
                });
        } else {
            arr = range(FAKE_SAMPLES).map((i) => ({
                x: new Date(latestTime).setMinutes(
                    date.getMinutes() + (i-FAKE_SAMPLES+1) * 5), // setMinutes returns timestamp
                y: 0,
            }));
        }

        setLatestTime(arr[arr.length-1].x);
        setPointArray(arr);
    }, [dailyOccupationData]);

    return (
        <StyledLiveEventsCumulative>
            <BlockWrapper>
                <Row>
                    <Col cols={{ xs: 5 / 12 }}>
                        <div className="title">
                            {t("dashboard.liveEventsCum.header")}
                        </div>
                    </Col>
                    <Col cols={{ xs: 2 / 12 }}>
                        <ValueBlock
                            label={t("dashboard.liveEventsCum.peak")}
                            value={max}
                        />
                    </Col>
                    <Col cols={{ xs: 2 / 12 }}>
                        <ValueBlock
                            label={t("dashboard.liveEventsCum.average")}
                            value={avg.toFixed(1)}
                        />
                    </Col>
                    <Col cols={{ xs: 3 / 12 }}>
                        <ValueBlock
                            label={t("dashboard.liveEventsCum.utilization")}
                            value={`${utilization.toFixed(2)} %`}
                        />
                    </Col>
                </Row>
                <Plot
                    data={[
                        {
                            type: "scatter",
                            line: {
                                shape: "hv",
                                color: cssVars.colors.blue,
                                width: 0.5,
                            },
                            fill: "tozeroy",
                            fillcolor: cssVars.colors.blue,
                            // x: x,
                            x: pointArray.map((item) => item.x),
                            // y: y,
                            y: pointArray.map((item) => item.y),
                            hoverinfo: "y",
                        },
                    ]}
                    layout={{
                        autosize: true,
                        margin: { t: 0, l: 20, r: 0, b: 10 },
                        font: { size: 8 },
                        showlegend: false,
                        // modebar: false,
                        // opacity: 0.8,
                        // spikedistance: -1,
                        // spikewidth: 1,
                        xaxis: {
                            type: "date",
                            showgrid: false,
                            zeroline: false,
                            fixedrange: true,
                            tickangle: 0,
                            tickformat: "%H:%M",
                            showspikes: true,
                            spikethickness: 1,
                            spikemode: "across",
                            spikedash: "solid",
                        },
                        yaxis: {
                            showgrid: false,
                            zeroline: true,
                            fixedrange: true,
                            rangemode: "tozero",
                        },
                    }}
                    useResizeHandler={true}
                    config={{ displayModeBar: false }}
                />
                <input
                    type="date"
                    value={formatDateInput(selectedDate)}
                    onChange={(event) => {
                        // Invalidate today when moving date (because it won't be up-to-date if we don't track it)
                        if (isToday)
                            queryClient.invalidateQueries({queryKey});
                        setSelectedDate(event.target.valueAsNumber);
                    }}
                />
            </BlockWrapper>
        </StyledLiveEventsCumulative>
    );
};

export default LiveEventsCumulative;
