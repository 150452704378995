import { useMemo } from "react";
import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type { SpaceOptions, ExtendedQueryResult } from "./base.js";
import type { DataMap } from "./multi.js";
import { multiPlaceholder, queryFnSingle, queryFnMulti } from "./multi.js";
import { GateInfo } from "../../api/v1/ReportInfo/index.js";
import { GateData } from "../../api/v1/ReportData/index.js";

interface SpaceDataOptions<Result> extends SpaceOptions<Result> {
    queryClient: QueryClient;
}

interface SpaceGateDataOptions<Result> extends SpaceDataOptions<Result> {
    gateId: string;
}

export function useSpaceGates({
    spaceId,
    enabled: enabledOption,
    placeholderData,
}: SpaceOptions<GateInfo[]>): UseQueryResult<GateInfo[]> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = ["Spaces", spaceId, "Gates"];
    return useQuery({
        enabled,
        queryKey,
        queryFn: baseQueryFn<GateInfo[]>,
        placeholderData,
        staleTime: staleTimeInfo,
    });
}

export function useSpaceGateLatestData({
    spaceId,
    gateId,
    enabled: enabledOption,
    placeholderData,
    queryClient,
}: SpaceGateDataOptions<GateData>): ExtendedQueryResult<GateData> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId && !!gateId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "Gates", gateId, "LatestData"]
    , [spaceId, gateId]);
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: queryFnSingle<GateData>,
            meta: {queryClient},
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}

export function useSpaceGatesLatestData({
    spaceId,
    enabled: enabledOption,
    placeholderData,
    queryClient,
}: SpaceDataOptions<DataMap<GateData>>): ExtendedQueryResult<DataMap<GateData>> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "Gates", multiPlaceholder, "LatestData"]
    , [spaceId]);
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: queryFnMulti<GateData>,
            meta: {queryClient},
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}
