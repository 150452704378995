import { AxiosResponse } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useQueryClient } from "@tanstack/react-query";
import { Image } from "../../../api/v1/image.js";
import { invalidateMe, useMe } from "../../../hooks/api.js";
import Button from "../../../components/UI/Button/Button.js";
import CroppedFileUpload from "../../../components/UI/CroppedFileUpload/CroppedFileUpload.js";
import TextInput from "../../../components/UI/TextInput/TextInput.js";
import FormWrapper from "../../../components/atoms/FormWrapper/FormWrapper.js";
import StyledFormWrapper from "../../../components/atoms/FormWrapper/FormWrapper.styles.js";
import InfoBlock from "../../../components/atoms/InfoBlock/InfoBlock.js";
import StyledInfoBlock from "../../../components/atoms/InfoBlock/InfoBlock.styles.js";
import mergeDefaultValues from "../../../utils/form-merge.js";
import RESTService from "../../../services/RESTService.js";
import { Col, Row } from "../../../styles/Grid.js";
import { UserAccountPostValues, UserAccountValues } from "../auth.js";
import { defaults, validation } from "./schema.js";

const UserAccountPage: React.FC = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [initVals, setInitVals] = useState<UserAccountValues>();
    const [submitDone, setSubmitDone] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);
    const [image, setImage] = useState<Image>(null);

    const { data: meData } = useMe({});
    useEffect(() => {
        if (meData) {
            setInitVals(mergeDefaultValues(defaults, meData));
            setImage(meData.image);
        }
    }, [meData]);

    if (!initVals) {
        return <></>;
    }

    //TODO: use for profile image task
    const addImageValuesToSubmit = (
        submitValues: UserAccountValues,
        imageString,
        imageId,
    ): UserAccountPostValues => {
        let submitPostValues = {
            ...submitValues,
            image: null,
        } as UserAccountPostValues;

        if (imageString) {
            submitPostValues = {
                ...submitPostValues,
                image: imageString,
            };
        } else if (imageId) {
            submitPostValues = {
                ...submitValues,
                image: null,
                image_identifier: imageId,
            };
        }

        return submitPostValues;
    };

    return (
        <StyledAccountPage>
            <Formik
                initialValues={initVals}
                validationSchema={validation}
                onSubmit={(
                    values: UserAccountValues,
                    {
                        setSubmitting,
                        setFieldError,
                    }: FormikHelpers<UserAccountValues>,
                ) => {
                    setSubmitDone(false);

                    //TODO: use for profile image task
                    const payload = addImageValuesToSubmit(
                        values,
                        values.form_image,
                        image?.identifier,
                    );

                    //TODO: verify PUT user enpoint with backend
                    void RESTService.putForm({
                        url: "User",
                        payload,
                        setSubmitting,
                        successCallback(res: AxiosResponse): void {
                            setSubmitDone(true);
                            invalidateMe({queryClient});
                            setTimeout(() => {
                                setSubmitDone(false);
                            }, 5000);
                        },
                        setError,
                        setFieldError,
                    });
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FormWrapper>
                            <Row>
                                <Col>
                                    <h2>{t("account.user.header")}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CroppedFileUpload
                                        name="form_image"
                                        label={t("attributes.profileImage")}
                                        image={image}
                                        deleteImageEvent={ () => setImage(null) }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextInput
                                        name="email"
                                        label={t("attributes.email")}
                                        autocomplete="username"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col cols={{ xx: 6 / 12 }}>
                                    <TextInput
                                        name="first_name"
                                        label={t("attributes.firstName")}
                                    />
                                </Col>
                                <Col cols={{ xx: 6 / 12 }}>
                                    <TextInput
                                        name="last_name"
                                        label={t("attributes.lastName")}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextInput
                                        name="phone_number"
                                        label={t("attributes.phoneNumber")}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        label={t("button.submit")}
                                        submitButton={true}
                                        isSubmitting={isSubmitting}
                                    />
                                </Col>
                            </Row>
                        </FormWrapper>
                    </Form>
                )}
            </Formik>

            {submitDone && (
                <Row>
                    <Col>
                        <InfoBlock
                            type="confirm"
                            body={t("account.updateMessage")}
                            isForm
                        />
                    </Col>
                </Row>
            )}
            {error && (
                <Row>
                    <Col>
                        <InfoBlock type="error" body={error} isForm />
                    </Col>
                </Row>
            )}

            <FormWrapper>
                <Row>
                    <Col>
                        <h2>{t("account.user.passwordChange.header")}</h2>
                        <p>{t("account.user.passwordChange.body")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            label={t("account.user.passwordChange.button")}
                            url="password-forgotten"
                            urlTarget="_blank"
                        />
                    </Col>
                </Row>
            </FormWrapper>
        </StyledAccountPage>
    );
};

const StyledAccountPage = styled.div`
    ${Col} {
        &:first-child {
            ${StyledFormWrapper} {
                float: right;
            }
        }

        &:last-child {
            ${StyledFormWrapper} {
                float: left;
                margin-left: 20px;
            }
        }

        ${StyledInfoBlock} {
            margin: 20px auto;
        }
    }
`;

export default UserAccountPage;
