import styled from "styled-components";
import { Col, Row } from "../../../styles/Grid.js";
import { blockTitle } from "../../../styles/block.js";
import cssVars from "../../../styles/cssVars.js";

const StyledDeviceList = styled.div`
  .title {
    ${blockTitle};
    border-bottom: 1px solid ${cssVars.colors.grayLight};
  }

  ${Row} {
    border-bottom: 1px solid ${cssVars.colors.grayLight};
    padding: 10px;

    font-size: ${cssVars.fontSizes.medium};

    ${Col} {
      position: relative;
    }
  }

  .deviceStatus {
    position: absolute;

    width: 12px;
    height: 12px;

    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);
    transition: background-color ${cssVars.transitionSpeeds.medium} ease-in-out;

    background: ${cssVars.colors.gray};

    &.ok {
      background: ${cssVars.colors.green};
    }

    &.none {
      background: ${cssVars.colors.gray};
    }
  }
`;

export default StyledDeviceList;
