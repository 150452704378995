"use client";

import { useQueryClient } from "@tanstack/react-query";
import { lightFormat, parseISO } from "date-fns";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import BarChart from "~/components/Dashboard/BarChart/BarChart.js";

import { useSpacePseudoVisitorCount } from "~/hooks/api.js";

type VisitorsPerWeekProps = {
  pseudoGateId: string;
};

const VisitorsPerWeek: React.FC<VisitorsPerWeekProps> = ({ pseudoGateId }) => {
  const queryClient = useQueryClient();
  const { id: spaceId } = useParams<{ id: string }>();

  // TODO: we are not able to show all weeks if we use the date filter, since it filters per week
  const now = new Date().valueOf();
  const fromDate = "2024-01-01";
  const tillDate = lightFormat(now, "yyyy-MM-dd");

  const { data } = useSpacePseudoVisitorCount({
    spaceId,
    from: fromDate,
    till: tillDate,
    interval: "Weekly",
    queryClient,
  });

  const visitorsData = useMemo(() => {
    const result = data?.[pseudoGateId] || {};

    return Object.entries(result).map(([key, value]) => ({
      date: lightFormat(parseISO(key), "MM-dd"),
      Visitors: value,
    }));
  }, [data]);

  return (
    <BarChart
      index="date"
      title="Visitors per week"
      desc={`${fromDate} to ${tillDate}`}
      categories={["Visitors"]}
      // @ts-expect-error TODO: fix types
      data={visitorsData}
    />
  );
};

export default VisitorsPerWeek;
