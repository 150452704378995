import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledLiveEvents = styled.div`
  ${StyledBlockWrapper} {
    .title {
      ${blockTitle};
    }

    .js-plotly-plot {
      width: 100%;
      height: 160px;
    }

    .liveLineWrapper {
      position: relative;

      width: 100%;
      height: 180px;

      margin-top: 20px;

      padding: 10px 0;

      .liveLine {
        position: absolute;

        top: 50%;

        width: 100%;
        height: 2px;

        background: ${cssVars.colors.grayLight};
      }

      .pointWrapper {
        position: relative;

        width: 100%;
        height: 130px;

        margin-top: 10px;

        .point {
          position: absolute;

          width: 7px;
          height: 7px;

          border-radius: 50%;

          background-color: ${cssVars.colors.green};

          transform: translateX(-50%) translateY(-50%);

          &:hover > div {
            display: block;
          }

          > div {
            position: absolute;
            display: none;

            top: 10px;
            left: 10px;

            padding: 3px 5px;
            font-size: ${cssVars.fontSizes.default};

            background: ${cssVars.colors.blue};
            color: ${cssVars.colors.white};
          }
        }
      }
    }
  }
`;

export default StyledLiveEvents;
