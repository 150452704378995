import { Field, FieldProps } from "formik";
import React from "react";
import Select, { OptionsOrGroups } from "react-select";
import ErrorElement from "../ErrorElement/ErrorElement.js";
import StyledSearchDropdown from "./SearchDropdown.styles.js";

interface Option {
    label: string;
    value: string;
}

type SearchDropdownFieldProps = {
    options: OptionsOrGroups<Option, undefined>;
} & FieldProps;

const SearchDropdownField: React.FC<SearchDropdownFieldProps> = ({
    field,
    form,
    options,
    ...props
}: SearchDropdownFieldProps) => {
    const { value } = field;
    function onChange(newValue) {
        const option = newValue as Option;
        console.log("New value", option);
        form.setFieldValue(field.name, option.value);
        // form.setFieldValue(field.name, option ? (option as Option[]).map((item: Option) => item.value) : []); //TODO: use this line again if possible
        // This checks if the value is in the options list?
    }
    console.log(`SearchDropDown ${field.name}`, options, props);

    return (
        <Select
            name={field.name}
            onChange={onChange}
            options={options}
            isMulti={false}
            value={options.find((option) => option.value == value)}
            {...props}
        />
    );
};

type SearchDropdownProps = {
    name: string;
    label?: string;
    options: {
        key: string | number;
        label: string;
    }[];
};

const SearchDropdown: React.FC<SearchDropdownProps> = ({
    name,
    label,
    options,
}: SearchDropdownProps) => {
    if (options == undefined || options.length == 0) {
        return <></>;
    }

    return (
        <StyledSearchDropdown>
            {label && <label>{label}</label>}
            <Field
                name={name}
                component={SearchDropdownField}
                placeholder=""
                options={options.map((item) => ({
                    value: item.key,
                    label: item.label,
                }))}
            />
            <ErrorElement name={name} />
        </StyledSearchDropdown>
    );
};

export default SearchDropdown;
