import { useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type {
  ExtendedQueryResult,
  SpaceDateOptions,
  SpaceOptions,
} from "./base.js";
import { multiPlaceholder } from "./multi.js";
import { UniqueCounterData } from "../../api/v1/ReportData/index.js";
import { UniqueCounterInfo } from "../../api/v1/ReportInfo/index.js";
import { formatApiDate } from "~/utils/date-time.js";

export function useSpaceUniqueCounters({
    spaceId,
    enabled: enabledOption,
    placeholderData,
}: SpaceOptions<UniqueCounterInfo[]>): UseQueryResult<UniqueCounterInfo[]> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = ["Spaces", spaceId, "UniqueCounters"];
    return useQuery({
        enabled,
        queryKey,
        queryFn: baseQueryFn<UniqueCounterInfo[]>,
        placeholderData,
        staleTime: staleTimeInfo,
    });
}

export function useSpaceUniqueCountersLatestData({
    spaceId,
    enabled: enabledOption,
    placeholderData,
}: SpaceOptions<UniqueCounterData>): ExtendedQueryResult<UniqueCounterData> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "UniqueCounters", multiPlaceholder, "LatestData"]
    , [spaceId]);

    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: baseQueryFn<UniqueCounterData>,
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}

export function useSpaceUniqueCountersData({
  spaceId,
  date,
  enabled: enabledOption,
  placeholderData,
}: SpaceDateOptions<UniqueCounterData>): UseQueryResult<UniqueCounterData> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId && !!date;
  const isToday = formatApiDate(new Date()) == date;
  const queryKey = useMemo(() =>
    isToday
      ? ["Spaces", spaceId, "UniqueCounters", multiPlaceholder, "LatestData"]
      : ["Spaces", spaceId, "UniqueCounters", multiPlaceholder, "Data", date]
  , [spaceId, date]);
  const staleTime = isToday ? staleTimeData : staleTimeInfo;

  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<UniqueCounterData>,
    placeholderData,
    staleTime,
  });
}
