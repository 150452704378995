import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CenteredLink from "../../../components/atoms/CenteredLink/CenteredLink.js";
import Register from "../../../components/auth/Register/Register.js";
import RESTService from "../../../services/RESTService.js";
import { RegisterValues } from "../auth.js";
import { defaults, validation } from "./schema.js";

const RegisterPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const [error, setError] = useState<string>(null);

    return (
        <StyledRegisterPage>
            <Formik
                initialValues={defaults}
                validationSchema={validation}
                onSubmit={(
                    values: RegisterValues,
                    {
                        setSubmitting,
                        setFieldError,
                    }: FormikHelpers<RegisterValues>,
                ) => {
                    void RESTService.postForm({
                        url: "Auth/Register",
                        payload: values,
                        successCallback(res: AxiosResponse): void {
                            history("/register/thankyou");
                        },
                        setSubmitting: setSubmitting,
                        setError: setError,
                        setFieldError: setFieldError,
                    });
                }}
            >
                {({ isSubmitting }) => (
                    <Register submit={isSubmitting} error={error} />
                )}
            </Formik>
            <CenteredLink to="/login" title={t("auth.login.backToLogin")} />
            {/*<Link to="/login">{t('auth.login.backToLogin')}</Link>*/}
        </StyledRegisterPage>
    );
};

const StyledRegisterPage = styled.div``;

export default RegisterPage;
