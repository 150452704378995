import React from "react";
import StyledBlockWrapper from "./BlockWrapper.styles.js";

type BlockWrapperProps = {
    children: React.ReactNode;
};

const BlockWrapper: React.FC<BlockWrapperProps> = ({
    children,
}: BlockWrapperProps) => <StyledBlockWrapper>{children}</StyledBlockWrapper>;

export default BlockWrapper;
