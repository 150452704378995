import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "~/constants.js";
import { SpaceData } from "~/api/v1/Space";
import { useSpaces } from "~/hooks/api.js";
import AuthService from "~/services/AuthService.js";
import Button from "~/components/UI/Button";
import OrganizationSelect, { Organization, noOrg } from "~/components/UI/OrganizationSelect";
import Header from "~/components/portal/Header";
import LocationCardPage from "~/components/pages/LocationCardPage";

const getHref = (space: SpaceData) => {
  return `/locations/${space.identifier}/dashboard/`;
};

const LocationsIndexPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const path = useLocation();
  const [organization, setOrganization] = useState<string>(noOrg.identifier);

  const isSuper = AuthService.hasRole(UserRole.SuperAdmin);
  const canCreate = AuthService.hasRole(UserRole.Admin);

  const { data: spaces } = useSpaces({});

  const orgs: Organization[] = useMemo(() =>
    (spaces ?? [])
      .map((space) => space.organization)
      .sort((l,r) => +(l.identifier > r.identifier) - +(l.identifier < r.identifier))
      .reduce((acc: Organization[], org) =>
        acc && acc[acc.length-1].identifier == org.identifier
        ? acc
        : [...acc, {
          identifier: org.identifier,
          name: org.name,
        }],
        [noOrg]),
    [spaces],
  );

  // TODO: use Locations w/ types to filter instead
  // and filter out subspaces using their superspace
  const spacesFiltered = useMemo(() =>
    spaces?.filter((space) => (
      space.location.type == "Building" &&
      ((organization == noOrg.identifier) || (organization == space.organization.identifier))
    )),
    [spaces, organization]);

  return (
    <>
      <Header title={t("locations.index.header.title")}>
        {isSuper && (
          <OrganizationSelect
            organizations={orgs}
            onChange={(identifier) => setOrganization(identifier)}
          />
        )}
        {canCreate && (
          <Button
            label={t("locations.index.header.button")}
            url="/locations/create"
          />
        )}
      </Header>
      <LocationCardPage
        spaces={spacesFiltered}
        getHref={getHref}
      />
    </>
  );
};

export default LocationsIndexPage;
