import { css } from "styled-components";
import cssVars from "./cssVars.js";

export const typoInputFont = css`
    font-family: ${cssVars.fonts.default};
    font-size: 14px;
    line-height: 20px;
`;

export const typoDashboardFont = css`
    font-family: ${cssVars.fonts.default};
    font-size: 16px;
    line-height: 24px;
`;
