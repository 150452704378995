import styled from "styled-components";
import StyledButton from "../../UI/Button/Button.styles.js";
import StyledTextInput from "../../UI/TextInput/TextInput.styles.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledHeader = styled.div`
  ${StyledBlockWrapper} {
    display: flex;
    //flex-flow: row wrap;

    width: 100%;

    padding: 8px 16px;

    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-family: "Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #333333;
    }

    ${StyledButton} {
      margin-left: auto;
      margin-right: 10px;

      button {
        margin-left: 10px;
      }
    }

    ${StyledTextInput} {
      width: 30%;

      input {
        width: 100%;
      }
    }
  }
`;

export default StyledHeader;
