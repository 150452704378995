import styled from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";
import StyledInfoBlock from "../../atoms/InfoBlock/InfoBlock.styles.js";

const StyledLogin = styled.div`
  width: 100%;
  ${StyledBlockWrapper},
  ${StyledInfoBlock} {
    width: 300px;
  }

  ${StyledBlockWrapper} {
    margin: 100px auto 40px;

    h1 {
      margin: ${cssVars.distances.headerDistances};
      padding: 0;
    }

    input,
    button {
      width: 100%;
    }
  }

  ${StyledInfoBlock} {
    margin: 20px auto;
  }
`;

export default StyledLogin;
