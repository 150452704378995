import { hour } from "msecs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePeakOccupancyData } from "./api.js";
import { DAYS_FULL, DAYS_SHORT } from "../constants";
import {
    parseHourMinute,
    startOfWeek,
} from "../utils/date-time";

type Props = {
    locationId: string;
    startTime?: string;
    endTime?: string;
    startOfWeekDate?: string;
};

function usePeakOccupancyInternal(
    spaceId: string,
    startOfWeekDate: string,
    startTimeHour: number,
    endTimeHour: number,
) {
    const xKeys = DAYS_FULL;

    const startOfWeekValue = startOfWeek(
        new Date(startOfWeekDate ? startOfWeekDate : new Date()),
    );

    const { data } = usePeakOccupancyData({
        spaceId,
        date: startOfWeekValue.getTime(),
    });

    const res = useMemo<{
        maxZ: number;
        data: number[][];
    }>(() => {
        const tempZ: number[][] = [];
        let maxZ = 0;

        for (
            let i = startTimeHour;
            i <= endTimeHour;
            i++
        ) {
            const normalizedIndex = Math.max(
                0,
                i - startTimeHour,
            );
            tempZ[normalizedIndex] = [] as number[];

            for (let j = 0; j < 7; j++) {
                tempZ[normalizedIndex][j] = 0;
            }
        }

        xKeys.forEach((item, index) => {
            const day = (data ?? [])[item];

            if (!day) return;

            for (
                let hourIndex = startTimeHour;
                hourIndex <= endTimeHour;
                hourIndex++
            ) {
                const hourValue =
                    day[
                        String(
                            Math.round(
                                hourIndex
                            ),
                        )
                    ];

                if (!hourValue) continue;

                const normalizedIndex = Math.max(
                    0,
                    hourIndex - startTimeHour,
                );

                tempZ[normalizedIndex][index] =
                    hourValue;

                if (
                    tempZ[normalizedIndex][index] > maxZ
                ) {
                    maxZ =
                        tempZ[normalizedIndex][index];
                }
            }
        });

        return {
            maxZ,
            data: tempZ,
        };
    }, [data]);

    const result = useMemo<number[][]>(() => {
        const result = [];
        if (!res || !res.data)
            return result;

        // Combine each array of arrays
        for (let i = 0; i < res.data.length; i++) {
            if (!result[i]) {
                result[i] = [];
            }

            for (let j = 0; j < res.data[i].length; j++) {
                if (!result[i][j]) {
                    result[i][j] = 0;
                }

                result[i][j] += res.data[i][j];
            }
        }
        return result;
    }, [res]);

    return {
        maxZ: res.maxZ,
        z: result,
    };
}

export default function usePeakOccupancy({
    locationId,
    startTime = "00:01",
    endTime = "23:59",
    startOfWeekDate,
}: Props) {
    const startTimeHour = Math.round(parseHourMinute(startTime) / hour);
    const endTimeHour = Math.round(parseHourMinute(endTime) / hour);
    const { t } = useTranslation();
    const x = DAYS_SHORT.map((day) => t(`dateValues.days.short.${day}`));

    const y = useMemo<string[]>(() =>
        Array.from(
            { length: endTimeHour - startTimeHour + 1 },
            (_, i) => (i + startTimeHour).toString() + ":00",
        )
    , [startTimeHour, endTimeHour]);

    const { maxZ, z } = usePeakOccupancyInternal(
        locationId,
        startOfWeekDate,
        startTimeHour,
        endTimeHour,
    );

    return { y, x, z, maxZ };
}
