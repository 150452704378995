import React from "react";
import { useTranslation } from "react-i18next";
import { OccupationState } from "~/constants.js";
import { SpaceData } from "~/api/v1/Space";
import { LocationOccupationData } from "../../../websockets/websockets.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledFloorOverview from "./FloorOverview.styles.js";
import FloorRow from "./FloorRow/FloorRow.js";

type FloorOverviewProps = {
    locations?: SpaceData[];
    wsCallback?: (id: string, loc: LocationOccupationData) => void;
};

const FloorOverview: React.FC<FloorOverviewProps> = ({
    locations,
    wsCallback,
}: FloorOverviewProps) => {
    const { t } = useTranslation();

    return (
        <StyledFloorOverview>
            <BlockWrapper>
                <div className="title">
                    {t("dashboard.floorOverview.header")}
                </div>
                {locations &&
                    // FIXME: The ordering here depends on the order that the API & DB return things, and is NOT reliable.
                    locations.map((floor, index) => (
                        <FloorRow
                            floorIndex={index}
                            floorCount={locations.length}
                            locationData={floor}
                            initialCount={0}
                            initialState={OccupationState.Ok}
                            key={`floor-${index}`}
                            wsCallback={wsCallback}
                        ></FloorRow>
                    ))}
            </BlockWrapper>
        </StyledFloorOverview>
    );
};

export default FloorOverview;
