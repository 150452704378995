import React from "react";
import { Image } from "../../../api/v1/image.js";
import StyledSmallCircleImage from "./SmallCircleImage.styles.js";

type SmallCircleImageProps = {
    image?: Image;
};

const SmallCircleImage: React.FC<SmallCircleImageProps> = ({
    image,
}: SmallCircleImageProps) => (
    <StyledSmallCircleImage>
        {image ? (
            <img src={image.url} className="image" />
        ) : (
            <div className="image" />
        )}
    </StyledSmallCircleImage>
);

export default SmallCircleImage;
