export enum Colors {
    white = "#FFFFFF",
    blue = "#2b365f",
    blueLight = "#30aae1",
    blueLightLight = "#67bde3",
    green = "#29b473",
    greenLight = "#81deb3",
    red = "#e84e25",
    orange = "#FC9E54",
    yellow = "#FC9E54",
    gray = "#DDDDDD",
    grayLight = "#F5F5F5",
    grayDark = "#666666",
    black = "#333333",
}

export enum Distances {
    marginBottom = "10px",
    headerDistances = "18px 0 22px 0",
    paddingBox = "10px",
    formWidth = "500px",
    smallPadding = "2px",
}

export enum BorderRadius {
    default = "3.75px",
}

export enum Fonts {
    default = "'Regular', sans-serif",
}

export enum FontSizes {
    default = "12px",
    medium = "14px",
    large = "18px",
}

enum TransitionSpeeds {
    slow = "0.6s",
    medium = "0.30s",
    fast = "0.15s",
}

const cssVars = {
    // breakpoints,
    // grid: {
    //   gutter,
    //   width: Breakpoints,
    // },
    // breakpointsArray,
    colors: Colors,
    borderRadius: BorderRadius,
    distances: Distances,
    fonts: Fonts,
    fontSizes: FontSizes,
    transitionSpeeds: TransitionSpeeds,
    // zIndexes: ZIndexes,
};

export default cssVars;
