import styled from "styled-components";
import StyledLogo from "../../atoms/Logo/Logo.styles.js";

const StyledBigScreen = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  max-width: 1080px;
  min-height: calc(100vh - 188px);

  padding: 94px 188px;

  ${StyledLogo} {
    width: 100%;
    margin-bottom: 20px;
  }

  .logo {
    max-width: 100%;
    max-height: 200px;
    margin-bottom: 20px;
  }

  .headerWrapper {
    text-align: center;

    font-weight: 700;
    text-shadow: 2.45926px 4.91852px 4.91852px rgba(0, 0, 0, 0.25);

    h1 {
      font-size: 3.8rem;
    }

    p {
      font-size: 2rem;
    }
  }

  .countWrapper {
    margin-top: 20px;

    text-align: center;

    .count {
      font-weight: 700;
      font-size: 4.2rem;
    }

    .countLabel {
      font-size: 2rem;
    }
  }

  .noData {
    visibility: hidden;
  }

  .poweredBy {
    position: absolute;

    left: 20px;
    bottom: 0px;

    ${StyledLogo} {
      margin: 5px 0 0 0;
      width: 210px;
    }
  }
`;

export default StyledBigScreen;
