import styled, { css } from "styled-components";
import cssVars from "../../../styles/cssVars.js";

const StyledErrorElement = styled.div<{ error: boolean }>`
  ${(props) =>
    props.error
      ? css`
          visibility: visible;
          padding: 5px 0 5px;
        `
      : css`
          visibility: hidden;
        `}

  color: ${cssVars.colors.red};
  font-weight: 500;
  font-size: 14px;
`;

export default StyledErrorElement;
