import styled from "styled-components";
import { Col } from "../../../styles/Grid.js";
import cssVars from "../../../styles/cssVars.js";

const StyledFormWrapper = styled.div`
  max-width: ${cssVars.distances.formWidth};
  margin: 20px auto;

  ${Col} {
    > div {
      margin: 5px;
    }

    input {
      width: 100%;
    }

    button {
      float: right;
    }
  }
`;

export default StyledFormWrapper;
