import React, { useEffect, useState } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import { UserRole } from "../constants.js";
import { useMe } from "../hooks/api.js";
import Navigation from "../components/portal/Navigation/Navigation.js";
import { NAVIGATION_ITEMS } from "../constants.js";
import { authenticatedRoutes, unauthenticatedRoutes } from "../routes.js";
import AuthService from "../services/AuthService.js";
import cssVars from "../styles/cssVars.js";
import GlobalStyle from "../styles/globalStyle.js";
import { WebSocketProvider } from "../websockets/WebSocketProvider.js";

const AuthorizedLayout = (props: any) => {
    const history = useNavigate();

    const {
        // location: { pathname },
        // history: { push },
        children,
    } = props;

    const location = useLocation();
    const [isAuthenticated, setAuthStatus] = useState(false);

    const isAuthPath = (path) => {
        return matchRoutes(authenticatedRoutes, path);
    };

    const isAdminPath = (path) => {
        return matchRoutes(authenticatedRoutes
            .filter((route) => route.admin_only)
            , path);
    };

    const isSuperAdminPath = (path) => {
        return matchRoutes(authenticatedRoutes
            .filter((route) => route.superadmin_only)
            , path);
    };

    const isPath = (path) => {
        return (
            matchRoutes(unauthenticatedRoutes, path) ||
            matchRoutes(authenticatedRoutes, path)
        );
    };

    useEffect(() => {
        AuthService.checkToken()
            // Ensure the layout can be rendered
            .then(AuthService.checkIsUser)
            .then(() => {
                setAuthStatus(true);
                if (isAdminPath(location.pathname) &&
                    !AuthService.hasRole(UserRole.Admin)) {
                    history("/");
                }
                if (isSuperAdminPath(location.pathname) &&
                    !AuthService.hasRole(UserRole.SuperAdmin)) {
                    history("/");
                }
            })
            .catch(() => {
                setAuthStatus(false);
                if (isAuthPath(location.pathname)) {
                    history("/login");
                }
            });
    }, [history, location.pathname]);

    const { data: meData } = useMe({
        enabled: isAuthenticated,
    });
    const profileImage = meData?.image;
    const navigationItems = NAVIGATION_ITEMS.filter((item) =>
        (!item.admin_only       || AuthService.hasRole(UserRole.Admin)) &&
        (!item.superadmin_only  || AuthService.hasRole(UserRole.SuperAdmin))
    );

    // if (!isAuthenticated && !isPath(location.pathname)) {
    //   return <div>404</div>;
    // }

    // This already provides a websocketprovider
    // That means only magic-token pages should need their own
    return (
        isAuthenticated && !!meData && (
            <>
                <WebSocketProvider
                    token={AuthService.getAuthToken()}
                    organizationIdentifier={meData?.organization?.identifier}
                >
                    <GlobalStyle />
                    <Navigation
                        links={navigationItems}
                        profileImage={profileImage}
                    />
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={300}
                        >
                            <StyledAuthorizedLayout>
                                <div>{children}</div>
                            </StyledAuthorizedLayout>
                        </CSSTransition>
                    </TransitionGroup>
                </WebSocketProvider>
            </>
        )
    );
};

const StyledAuthorizedLayout = styled.div`
    position: relative;
    margin-left: 90px;

    > div {
        position: absolute;

        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;

        padding: 10px;

        min-height: calc(100vh);

        background: ${cssVars.colors.white};
    }

    &.fade-enter {
        animation-name: fadeIn;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        z-index: 10;
    }

    &.fade-exit {
        animation-name: fadeIn;
        animation-direction: reverse;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export default AuthorizedLayout;
