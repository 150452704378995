import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledFloorOverview = styled.div`
  //margin-bottom: 150px;

  ${StyledBlockWrapper} {
    padding-bottom: 150px;
  }

  .title {
    ${blockTitle};
    font-size: ${cssVars.fontSizes.large};
    border-bottom: 1px solid ${cssVars.colors.grayLight};
  }
`;

export default StyledFloorOverview;
