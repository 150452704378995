import React from "react";
import { useTranslation } from "react-i18next";
import type { OccupationState } from "../../../constants.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import DistanceBlock from "../../atoms/DistanceBlock/DistanceBlock.js";
import StyledMiniInfoCardCapacity from "./MiniInfoCardCapacity.styles.js";

type MiniInfoCardCapacityProps = {
    count: number;
    maxCount: number;
    state: OccupationState;
    reconnect?: boolean;
};

const MiniInfoCardCapacity: React.FC<MiniInfoCardCapacityProps> = ({
    count,
    maxCount,
    state,
    reconnect,
}: MiniInfoCardCapacityProps) => {
    const { t } = useTranslation();

    if (reconnect) {
        return (
            <StyledMiniInfoCardCapacity>
                <BlockWrapper>
                    <div className="title">
                        {t("dashboard.capacity.header")}
                    </div>

                    <div className="countWrapper">
                        {t("dashboard.capacity.lostConnection")}
                    </div>
                </BlockWrapper>
            </StyledMiniInfoCardCapacity>
        );
    }

    return (
        <StyledMiniInfoCardCapacity>
            <BlockWrapper>
                <div className="title">{t("dashboard.capacity.header")}</div>
                <div className="countWrapper">
                    <div className="count">
                        {count} / {maxCount}
                    </div>
                    <DistanceBlock state={state} />
                </div>
            </BlockWrapper>
        </StyledMiniInfoCardCapacity>
    );
};

export default MiniInfoCardCapacity;
