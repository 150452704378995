import React from "react";
import StyledArrowButton from "./ArrowButton.styles.js";

type ArrowButtonProps = {
    mirror?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ArrowButton: React.FC<ArrowButtonProps> = ({
    mirror = false,
    onClick,
}: ArrowButtonProps) => (
    <StyledArrowButton mirror={mirror}>
        <button type="button" onClick={onClick}></button>
    </StyledArrowButton>
);

export default ArrowButton;
