import { useTranslation } from "react-i18next";
import { DAYS_SHORT } from "~/constants";
import { useSpaceUniqueCountersData } from "~/hooks/api";
import { formatApiDate } from "~/utils/date-time.js";

type Props = {
  spaceId: string;
  startOfWeekDate: string;
};

type GraphValue = {
  name: string;
  Total?: number;
  Walkers?: number;
  Runners?: number;
  Cyclists?: number;
};

type GraphData = {
  data: GraphValue[];
  total: {
    Walkers: number;
    Runners: number;
    Cyclists: number;
  };
  categories: string[];
};

export default function useUniqueWeekCountsHack({
  spaceId,
  startOfWeekDate,
}: Props): GraphData {
  const { t } = useTranslation();
  const now = new Date();

  const totals = {
    Walkers: 0,
    Runners: 0,
    Cyclists: 0,
  };

  const generateDayData = (day: string, index: number): GraphValue => {
    const thisDate = new Date(startOfWeekDate);
    thisDate.setDate(thisDate.getDate() + index);

    const dayName = t(`dateValues.days.short.${day}`);
    const name = `${dayName[0].toUpperCase()}${dayName.substring(1)}`;
    const formattedDate = formatApiDate(thisDate);
    const enabled = thisDate <= now;

    const { data } = useSpaceUniqueCountersData({
      spaceId,
      date: formattedDate,
      enabled,
    });

    const walkers = data?.person ?? 0;
    const runners = data?.runner ?? 0;
    const cyclists = data?.bike ?? 0;

    totals.Walkers += walkers;
    totals.Runners += runners;
    totals.Cyclists += cyclists;

    return {
      name,
      Total: data?.total ?? 0,
      Walkers: walkers,
      Runners: runners,
      Cyclists: cyclists,
    };
  };

  const data = DAYS_SHORT.map((day, index) => generateDayData(day, index));

  // Generate graph categories (lowest count items first)
  const categories = Object.entries(totals)
    .sort(([, a], [, b]) => a - b) // Sort by the count value in ascending order
    .map(([key]) => key);

  return {
    data,
    total: totals,
    categories,
  };
}
