import styled from "styled-components";
import { typoDashboardFont } from "../../../styles/cssTypography.js";
import cssVars from "../../../styles/cssVars.js";
import StyledDistanceBlock from "../../atoms/DistanceBlock/DistanceBlock.styles.js";
import StyledIcon from "../../atoms/Icon/Icon.styles.js";
const StyledLocationCard = styled.div`
  ${typoDashboardFont};

  a {
    color: unset;
  }

  .image {
    display: block;

    width: 100%;
    height: 230px;

    background: ${cssVars.colors.white};

    margin-bottom: 20px;

    object-fit: cover;
  }

  .locationCity {
    font-size: 14px;
    color: ${cssVars.colors.gray};
  }

  .countWrapper {
    padding: 10px 7px 7px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .count {
      height: 48px;
      font-size: 22.5px;
      line-height: 48px;
      font-weight: 700;
    }

    ${StyledDistanceBlock} {
      align-self: flex-end;

      transform: scale(0.8);
      margin-top: -6px;

      // > div {
      //   width: 48px;
      //   height: 48px;
      // }
      //
      // .dangerStroke {
      //
      // }
      //
      // ${StyledIcon} {
      //   svg {
      //     width: 30px;
      //     height: 30px;
      //   }
      // }
    }
  }
`;

export default StyledLocationCard;
