import styled from "styled-components";
import { defaultInputStyle } from "../../../styles/cssInput.js";

const StyledTextAreaInput = styled.div`
  label,
  label + textarea {
    display: block;
  }

  label {
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 5px;
  }

  textarea {
    ${defaultInputStyle};

    width: 100%;
  }
`;

export default StyledTextAreaInput;
