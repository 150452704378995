import styled from "styled-components";
import { boxShadow } from "../../../styles/boxShadow.js";
import cssVars from "../../../styles/cssVars.js";
import StyledIcon from "../../atoms/Icon/Icon.styles.js";
import StyledSmallCircleImage from "../../atoms/SmallCircleImage/SmallCircleImage.styles.js";
import StyledLogoutButton from "../../auth/LogoutButton/LogoutButton.styles.js";

const StyledNavigation = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;

  width: 80px;

  padding: 16px;

  background: ${cssVars.colors.white};

  ${boxShadow(2, 0)};

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  .iconWrapper {
    width: 48px;
    height: 48px;

    padding: 4px;

    background: ${cssVars.colors.white};

    border-radius: 12px;

    ${boxShadow(0, 0)};
  }

  .iconWrapper,
  ${StyledIcon} {
    margin-bottom: 40px;
  }

  .active {
    svg path:not(.excludeFill) {
      fill: ${cssVars.colors.green};
    }
  }

  .pullDown {
    margin-top: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    ${StyledSmallCircleImage},
    ${StyledLogoutButton} {
      margin-top: 20px;

      ${StyledIcon} {
        margin-bottom: 0px !important;
      }
    }
  }

  // ${StyledSmallCircleImage},
  // ${StyledLogoutButton} {
  //   margin-top: auto;
  // }
`;

export default StyledNavigation;
