import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PasswordReset from "../../../components/auth/PasswordReset/PasswordReset.js";
import AuthService from "../../../services/AuthService.js";
import RESTService from "../../../services/RESTService.js";
import { LoginData, RestorePasswordValues } from "../auth.js";
import { defaults, validation } from "./schemaPasswordReset.js";

const PasswordResetPage: React.FC = () => {
    const { user_id, reset_token } = useParams<PasswordResetParamProps>();
    const [error, setError] = useState<string>("");

    return (
        <StyledPasswordResetPage>
            <Formik
                initialValues={defaults}
                validationSchema={validation}
                onSubmit={(
                    values: RestorePasswordValues,
                    {
                        setSubmitting,
                        setFieldError,
                    }: FormikHelpers<RestorePasswordValues>,
                ) => {
                    const payload = {
                        token: decodeURIComponent(reset_token),
                        id: user_id,
                        password: values.password,
                        confirm_password: values.password_repeat,
                    };

                    void RESTService.postForm<LoginData>({
                        url: "Auth/ResetPassword",
                        payload: payload,
                        successCallback(res: AxiosResponse<LoginData>): void {
                            AuthService.setAuthToken(res.data.access_token);
                            AuthService.setRefreshToken(res.data.refresh_token);
                            window.location.href = "/";
                            // history.push('/');
                        },
                        setSubmitting: setSubmitting,
                        setError: setError,
                        setFieldError: setFieldError,
                    });
                }}
            >
                {({ isSubmitting }) => (
                    <PasswordReset submit={isSubmitting} error={error} />
                )}
            </Formik>
        </StyledPasswordResetPage>
    );
};

type PasswordResetParamProps = {
    user_id: string;
    reset_token: string;
};

const StyledPasswordResetPage = styled.div``;

export default PasswordResetPage;
