import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { invalidateMe } from "../../../hooks/api.js";
import AuthService from "../../../services/AuthService.js";
import Icon from "../../atoms/Icon/Icon.js";
import StyledLogoutButton from "./LogoutButton.styles.js";

type LogoutButtonProps = {
    //
};

const LogoutButton: React.FC<LogoutButtonProps> = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const queryClient = useQueryClient();

    const buttonClicked = () => {
        AuthService.invalidateToken();
        invalidateMe({queryClient});
        history("/login");
    };

    return (
        <StyledLogoutButton>
            <Icon name="logout" size={24} click={buttonClicked} />
            {/*<Button label={t('auth.logout')} onClick={buttonClicked} />*/}
        </StyledLogoutButton>
    );
};

export default LogoutButton;
