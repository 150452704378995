import { UseQueryResult } from "@tanstack/react-query";
import RESTService from "../../services/RESTService.js";

export interface OrgOptions<Result> {
    orgId: string;
    spaceId?: string;
    enabled?: boolean;
    initialData?: Result;
}

export interface SpaceOptions<Result> {
    spaceId: string;
    enabled?: boolean;
    placeholderData?: Result;
}

export interface SpaceDateOptions<Result> extends SpaceOptions<Result> {
  date: string;
}

export const staleTimeData = 5 * 60 * 1000;
export const staleTimeInfo = 60 * 60 * 1000;

export async function baseQueryFn<Type>({queryKey}): Promise<Type> {
    return await RESTService.getAsync<Type>({
        url: queryKey.join('/'),
    });
}

export type ExtendedQueryResult<TResult> = UseQueryResult<TResult> & {
    queryKey: string[],
    // TODO: invalidate callback? not needed now, but might be useful
    // to pass around to where queryClient is not easily available
}
