import { object } from "yup";
import { getCurrentLanguageTranslation } from "../../../locales/helpers/index.js";
import PasswordService from "../../../services/PasswordService.js";

export const defaults = {
    password: "",
    password_repeat: "",
};

const { validationRules: msg } = getCurrentLanguageTranslation();

export const validation = object().shape({
    password: PasswordService.validatedPasswordRules(msg, false),
    password_repeat: PasswordService.validatedPasswordRules(msg, true),
});
