import styled from "styled-components";

const StyledDashboard = styled.div`
  padding: 20px 0;

  h2 {
    margin-bottom: 14px;
  }

  .infoCards {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &.pushRight {
      padding-right: 20px;
    }

    > div {
      width: 100%;
    }
  }
`;

export default StyledDashboard;
