"use client";

import { useQueryClient } from "@tanstack/react-query";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  lightFormat,
  parseISO,
  startOfWeek,
} from "date-fns";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import AreaChart from "~/components/Dashboard/AreaChart/AreaChart.js";

import { useSpacePseudoVisitorCount } from "~/hooks/api.js";

type VisitorsPerDayProps = {
  pseudoGateId: string;
  startDate: string;
};

const VisitorsPerDay: React.FC<VisitorsPerDayProps> = ({
  pseudoGateId,
  startDate,
}) => {
  const queryClient = useQueryClient();
  const { id: spaceId } = useParams<{ id: string }>();

  const fromDate = startOfWeek(parseISO(startDate), { weekStartsOn: 1 });
  const tillDate = endOfWeek(parseISO(startDate), { weekStartsOn: 1 });

  const fromDateFormatted = lightFormat(fromDate, "yyyy-MM-dd");
  const tillDateFormatted = lightFormat(tillDate, "yyyy-MM-dd");

  const { data } = useSpacePseudoVisitorCount({
    spaceId,
    from: fromDateFormatted,
    till: tillDateFormatted,
    interval: "Daily",
    queryClient,
  });

  const visitorsData = useMemo(() => {
    const result = data?.[pseudoGateId] || {};
    const weekDates = eachDayOfInterval({ start: fromDate, end: tillDate });

    return weekDates.map((date) => {
      return {
        date: format(date, "EEE"),
        Visitors: result[lightFormat(date, "yyyy-MM-dd")] || 0,
      };
    });
  }, [data]);

  return (
    <AreaChart
      index="date"
      title="Visitors per day"
      categories={["Visitors"]}
      // @ts-expect-error TODO: fix types
      data={visitorsData}
    />
  );
};

export default VisitorsPerDay;
