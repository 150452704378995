import { number, object, string } from "yup";
import { getCurrentLanguageTranslation } from "../../../locales/helpers/index.js";

export const defaults = {
    name: "",
    c_o_c_number: "",
    postal_code: "",
    house_number: "",
    house_number_suffix: "",
    phone_number: "",
    street: "",
    city: "",
    billing_contact: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
    },
};

const { validationRules: msg } = getCurrentLanguageTranslation();

export const validation = object().shape({
    name: string().nullable(),
    c_o_c_number: string().min(6, msg.string.min).nullable(),
    postal_code: string().nullable(),
    house_number: number().nullable(),
    house_number_suffix: string().nullable(),
    phone_number: string().nullable(),
    street: string().nullable(),
    city: string().nullable(),
    billing_contact: object().shape({
        email: string().nullable().email(msg.string.email),
        first_name: string().nullable(),
        last_name: string().nullable(),
        phone: string().nullable(),
    }),
});
