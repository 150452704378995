import { useEffect, useRef } from "react";
import {
    joinWsGroup,
    useWebSocketContext,
} from "./WebSocketProvider.js";

export default function useWebSocket(
    methodName: string | string[] | null,
    methodFunction: (message: string) => void,
    organizationId?: string,
) {
    const { websocket, state, dispatch, orgId } = useWebSocketContext();
    const stateRef = useRef(state);
    stateRef.current = state;

    const org = organizationId ? organizationId : orgId;
    useEffect(() => {
        if(methodName === null) {
            return () => {/* nothing */};
        } else if(methodName instanceof Array) {
            const cleanups = methodName.map((singleMethodName) =>
                joinWsGroup({
                    websocket,
                    stateRef,
                    dispatch,
                    methodName: singleMethodName,
                    methodFunction,
                    organizationId: org,
                })
            );
            return () => cleanups.forEach((cleanup) => cleanup());
        } else {
            return joinWsGroup({
                websocket,
                stateRef,
                dispatch,
                methodName,
                methodFunction,
                organizationId: org,
            });
        }
    }, [websocket, methodName, methodFunction, org]);
}
