import styled, { css } from "styled-components";
import {
  defaultInputLabel,
  defaultInputStyle,
} from "../../../styles/cssInput.js";
import { typoInputFont } from "../../../styles/cssTypography.js";
import cssVars from "../../../styles/cssVars.js";

type TextInputStylesProps = {
  smallHeight?: boolean;
  smallWidth?: boolean;
};

const StyledTextInput = styled.div<TextInputStylesProps>`
  label,
  label + input {
    display: block;
  }

  label {
    ${defaultInputLabel};
  }

  input {
    ${defaultInputStyle};

    ${(props) =>
      props.smallHeight &&
      css`
        padding-top: ${cssVars.distances.smallPadding};
        padding-bottom: ${cssVars.distances.smallPadding};
      `}

    ${(props) =>
      props.smallWidth &&
      css`
        padding-left: ${cssVars.distances.smallPadding};
        padding-right: ${cssVars.distances.smallPadding};
        text-align: center;
      `}
  }

  input[type="password"] {
    font: small-caption;
    font-size: 15px;

    -webkit-text-security: square !important;

    letter-spacing: 4px;

    &::placeholder,
    &::-webkit-input-placeholder {
      ${typoInputFont};
      letter-spacing: normal;
    }
  }

  input[type="time"] {
    padding-right: 3px;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;

export default StyledTextInput;
