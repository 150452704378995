import styled from "styled-components";
import {
  BreakpointString,
  cssRulePerBreakpoint,
} from "../../../styles/breakpoints";

export type BreakpointObjectGrid = Partial<Record<BreakpointString, number>>;

type GapValue = number | { x?: number; y?: number };
type BreakpointObjectGap = Partial<Record<BreakpointString, GapValue>>;

export type StyledGridWrapperProps = {
  numberOfColumns: number | BreakpointObjectGrid;
  gap?: GapValue | BreakpointObjectGap;
};

const generateGridTemplateColumns = (
  numberOfColumns: number | BreakpointObjectGrid,
): string => {
  if (typeof numberOfColumns === "number") {
    return `grid-template-columns: repeat(${numberOfColumns}, minmax(0, 1fr))`;
  }

  const breakpoints = { ...numberOfColumns };
  const hasLowestBreakpoint = "xx" in breakpoints;

  if (!hasLowestBreakpoint) {
    breakpoints.xx = 1;
  }

  return cssRulePerBreakpoint(
    breakpoints,
    (val) => `grid-template-columns: repeat(${val}, minmax(0, 1fr))`,
  );
};

const generateGridGap = (gap: GapValue | BreakpointObjectGap): string => {
  const gapValueToCss = (val: GapValue): string => {
    if (typeof val === "number") {
      return `gap: ${val}px`;
    }

    return `column-gap: ${val.x || 0}px; row-gap: ${val.y || 0}px`;
  };

  if (typeof gap === "number") {
    return gapValueToCss(gap);
  }

  if ("x" in gap || "y" in gap) {
    return gapValueToCss(gap);
  }

  return cssRulePerBreakpoint(
    gap as Partial<Record<BreakpointString, GapValue>>,
    (val) => gapValueToCss(val),
  );
};

const StyledGridWrapper = styled.div<StyledGridWrapperProps>`
  display: grid;

  ${(props) => generateGridTemplateColumns(props.numberOfColumns)};

  ${(props) => (props.gap ? generateGridGap(props.gap) : "gap: 0")};
`;

export default StyledGridWrapper;
