import { down } from "styled-breakpoints";
import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import { typoDashboardFont } from "../../../styles/cssTypography.js";
import cssVars from "../../../styles/cssVars.js";

const StyledPopularTimes = styled.div`
  ${typoDashboardFont};
  .title {
    ${blockTitle};
    border-bottom: 1px solid ${cssVars.colors.grayLight};
  }

  .js-plotly-plot {
    width: 100%;
    height: 160px;
  }

  ${down("sm")} {
    .xaxislayer-above .xtick:nth-child(even) {
      display: none;
    }
  }
`;

export default StyledPopularTimes;
