import { lightFormat, startOfWeek as startOfWeekDateFns } from "date-fns";
import { hour, minute } from "msecs";

export function formatApiDate(dateTime: Date | number) {
    dateTime = new Date(dateTime);
    const parts = new Array<string>();
    parts.push(String(dateTime.getFullYear()).padStart(4, "0"));
    parts.push(String(dateTime.getMonth() + 1).padStart(2, "0"));
    parts.push(String(dateTime.getDate()).padStart(2, "0"));
    return parts.join("-");
}

export function formateDisplayDate(dateTime: Date | number) {
    return lightFormat(dateTime, "dd MM yyyy");
}

export function formatDateInput(dateTime: Date | number) {
    dateTime = new Date(dateTime);
    const parts = new Array<string>();
    parts.push(String(dateTime.getFullYear()).padStart(4, "0"));
    parts.push(String(dateTime.getMonth() + 1).padStart(2, "0"));
    parts.push(String(dateTime.getDate()).padStart(2, "0"));
    return parts.join("-");
}

export function parseHourMinute(value: string) {
    const parts = value.split(":").map(Number);
    if (parts.length !== 2) {
        throw new Error(`unexpected value ${value}`);
    }

    if (parts.some(isNaN)) {
        throw new Error(`unexpected isNaN for value ${value}`);
    }

    return parts[0] * hour + parts[1] * minute;
}

export function formatHourMinute(value: number) {
    const hours = Math.floor(value / hour);
    const minutes = Math.floor((value % hour) / minute);

    return [
        String(hours).padStart(2, "0"),
        String(minutes).padStart(2, "0"),
    ].join(":");
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export function getCurrentTimeZoneOffset() {
    return new Date().getTimezoneOffset() * minute;
}

// Date-fns startOfWeek starts on Sunday, we want Monday
export const startOfWeek = (date: number | Date): Date =>
    startOfWeekDateFns(date, { weekStartsOn: 1 });
