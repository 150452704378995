import styled from "styled-components";
import { defaultInputStyle } from "../../../styles/cssInput.js";
import { typoInputFont } from "../../../styles/cssTypography.js";
import cssVars from "../../../styles/cssVars.js";
import StyledErrorElement from "../ErrorElement/ErrorElement.styles.js";

const StyledPasswordInput = styled.div`
  margin-bottom: ${cssVars.distances.marginBottom};

  label,
  label + input {
    display: block;
  }

  label {
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 5px;
  }

  input {
    ${defaultInputStyle};
  }

  input[type='password'] {
    font: small-caption;
    font-size: 15px;

    -webkit-text-security: square !important;

    letter-spacing: 4px;

    &::placeholder,
    &::-webkit-input-placeholder {
      ${typoInputFont};
      letter-spacing: normal;
    }
  }

  ${StyledErrorElement} {
    padding: 3px 0;
  }

  ul {
    list-style: none;
    
    margin: 0;
    padding: 0;
    
    li {
      position: relative;
      
      padding-left: 20px;
      margin-bottom: 3px;
      
      font-size: 14px;

      transition: color 1s ease-in-out;

      &:before {
        content: "";

        width: 10px;
        height: 10px;

        position: absolute;

        top: 3px;
        left: 3px;

        transition: background-color 1s ease-in-out, border-radius 1s ease-in-out;
        -webkit-transition: background-color 1s ease-in-out, border-radius 1s ease-in-out;
      }
    }
  }
  
  .red {
    color: ${cssVars.colors.red};

    &:before {
      background-color: ${cssVars.colors.grayLight};
    }
  }

  .green {
    color: ${cssVars.colors.green};
    
    &:before {
      background-color: ${cssVars.colors.green};
    }
  }

  .green:nth-child(2){
    color: ${cssVars.colors.blueLight};
  
    &:before {
      transform: scale(0.9);
      background-color: ${cssVars.colors.blueLight};
    }
  }

  .green:nth-child(3) {
    color: ${cssVars.colors.green};

    &:before {
      background-color: ${cssVars.colors.green};
      border-radius: 50%;
    }
  }

  .green:nth-child(4) {
    color: ${cssVars.colors.blue};

    &:before {
      border-radius: 50%;
      transform: scale(0.7);
      background-color: ${cssVars.colors.blue};
    }
  }

  .green:nth-child(5) {
    color: ${cssVars.colors.blueLight};

    &:before {
      transform: scale(0.7);
      background-color: ${cssVars.colors.blueLight};
    }
  }

  progress {
    width: 100%;
    height: 15px;

    margin-top: 5px;
    
    border-radius: 0;

    background-color: ${cssVars.colors.white};
    -webkit-appearance: none;
    appearance: none;
    
    &::-webkit-progress-bar{
      background: ${cssVars.colors.grayLight};
    }
    
    &::-webkit-progress-value {
      transition: background-color 1s ease-in-out, width 1s ease-in-out;
    }
  }

  progress[value='1'] {
    &::-webkit-progress-value{
      background: ${cssVars.colors.red};
    }
  }
  progress[value='2'] {
    &::-webkit-progress-value{
      background: ${cssVars.colors.orange};
    }
  }
  progress[value='3'] {
    &::-webkit-progress-value{
      background: ${cssVars.colors.yellow};
    }
  }
  progress[value='4'] {
    &::-webkit-progress-value{
      background: ${cssVars.colors.blue};
    }
  }
  progress[value='5'] {
    &::-webkit-progress-value{
      background: ${cssVars.colors.green};
    }
  }
  
}
`;

export default StyledPasswordInput;
