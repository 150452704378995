import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button.js";
import StyledFileUpload from "./FileUpload.styles.js";

type FileUploadProps = {
    handleFile: (file: File) => void;
};

const FileUpload: React.FC<FileUploadProps> = ({
    handleFile,
}: FileUploadProps) => {
    const { t } = useTranslation();
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];

        if (typeof handleFile == "function") handleFile(fileUploaded);
    };

    return (
        <StyledFileUpload>
            <Button
                label={t("croppedFileUpload.uploadFile")}
                onClick={handleClick}
            />
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
            />
        </StyledFileUpload>
    );
};

export default FileUpload;
