import styled from "styled-components";
import { defaultInputLabel } from "../../../styles/cssInput.js";
import cssVars from "../../../styles/cssVars.js";
import StyledIcon from "../../atoms/Icon/Icon.styles.js";

const StyledCroppedFileUpload = styled.div`
  label {
    ${defaultInputLabel};

    width: 100%;
  }

  img {
    max-width: 100%;
  }

  .cropContainer {
    position: relative;
    width: 100%;
    height: 300px;
    background: ${cssVars.colors.grayLight};
  }

  ${StyledIcon} {
    margin: 5px;

    border-radius: ${cssVars.borderRadius.default};
    float: right;

    svg {
      path {
        stroke: ${cssVars.colors.white};
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.goodCropping {
      padding: 10px 10px 7px 10px;
      background: ${cssVars.colors.green};
    }
    &.badCropping {
      padding: 8px 9px 7px 9px;
      background: ${cssVars.colors.red};
    }
  }
`;

export default StyledCroppedFileUpload;
