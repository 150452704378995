import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FloorOverview from "../../../components/Dashboard/FloorOverview/FloorOverview.js";
import StyledFloorOverview from "../../../components/Dashboard/FloorOverview/FloorOverview.styles.js";
import LocationCard from "../../../components/locations/LocationCard/LocationCard.js";
import StyledLocationCard from "../../../components/locations/LocationCard/LocationCard.styles.js";
import Header from "../../../components/portal/Header/Header.js";
import { OccupationState } from "../../../constants.js";
import { useSpaces } from "../../../hooks/api.js";
import { LocationOccupationData } from "../../../websockets/websockets.js";

interface counterStateItem {
  id: string;
  occ: number;
}

const LocationsIndexDemoPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const path = useLocation();
  const [counterStateList, setCounterStateList] = useState<counterStateItem[]>(
    [],
  );

  useEffect(() => {
    if (path.pathname == "/") {
      history("/locations");
    }
  }, []);

  // TODO: use Locations w/ types to filter instead
  const { data: spaces } = useSpaces({});
  const locations = useMemo(
    () =>
      [...(spaces ?? [])]
        ?.reverse()
        ?.filter((item) => item.location.type == "Building"),
    [spaces],
  );

  const locationImage = useMemo(() => {
    if (locations && locations[locations.length - 1])
      return locations[locations.length - 1].image;
    else return null;
  }, [locations]);

  const counterMaxNumber = useMemo(() => {
    return (locations ?? []).reduce((acc, loc) => {
      return acc + loc.capacity;
    }, 0);
  }, [locations]);

  const counterNumber = useMemo(() => {
    return (counterStateList ?? []).reduce((acc, counter) => {
      return acc + counter.occ;
    }, 0);
  }, [counterStateList]);

  const counterState = useMemo(() => {
    let state = OccupationState.Ok;
    if (counterNumber > counterMaxNumber * 0.6) state = OccupationState.Warning;
    if (counterNumber > counterMaxNumber * 0.8) state = OccupationState.Danger;

    return state;
  }, [counterNumber, counterMaxNumber]);

  const wsCallback = useCallback((id: string, loc: LocationOccupationData) => {
    const { curOccupation } = loc;

    setCounterStateList((counterStateList) => {
      const list = counterStateList.filter((item) => item.id != id);

      list.push({ id: id, occ: curOccupation });

      return list;
    });
  }, []);

  return (
    <>
      <Header title={t("locations.index.header.title")}>
        {/*<Button label={t('locations.index.header.button')} url="/locations/create" />*/}
      </Header>
      <StyledLocationIndexDemoPage>
        {locations && locations[0] && (
          <LocationCard
            key="count-apollo14"
            image={locationImage}
            initialCount={counterNumber}
            initialMaxCount={counterMaxNumber}
            initialState={counterState}
            locationCity="Saturnusstraat 14, 2516AH, The Hague, Netherlands"
            locationName="Apollo 14"
            href="/locations/apollo14"
            locationId={null}
          />
        )}
        <FloorOverview locations={locations} wsCallback={wsCallback} />
      </StyledLocationIndexDemoPage>
    </>
  );
};

const StyledLocationIndexDemoPage = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 10px 0 0 -10px;

  ${StyledLocationCard} {
    width: 255px;

    margin: 10px;
  }

  ${StyledFloorOverview} {
    display: none;
  }
`;

export default LocationsIndexDemoPage;
