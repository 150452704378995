import React from "react";
import Icon from "../Icon/Icon.js";
import StyledInfoBlock from "./InfoBlock.styles.js";

type InfoBlockProps = {
    type: string;
    body: string;
    isForm?: boolean;
};

const InfoBlock: React.FC<InfoBlockProps> = ({
    type,
    isForm,
    body,
}: InfoBlockProps) => {
    console.log(isForm);

    if (type == "confirm") {
        return (
            <StyledInfoBlock type={type} isForm={isForm}>
                <Icon name="checksmall" />
                <span dangerouslySetInnerHTML={{ __html: body }} />
            </StyledInfoBlock>
        );
    }
    if (type == "warning") {
        return (
            <StyledInfoBlock type={type} isForm={isForm}>
                <Icon name="exclamation" size={14} />
                <span dangerouslySetInnerHTML={{ __html: body }} />
            </StyledInfoBlock>
        );
    }

    if (type == "error") {
        return (
            <StyledInfoBlock type={type} isForm={isForm}>
                <Icon name="barsmall" size={14} />
                <span dangerouslySetInnerHTML={{ __html: body }} />
            </StyledInfoBlock>
        );
    }
    return <></>;
};

export default InfoBlock;
