import styled from "styled-components";

const StyledPageNotFound = styled.div`
  display: block;
  text-align: center;

  margin-bottom: 20px;
`;

export default StyledPageNotFound;
