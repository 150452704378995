import React from "react";
import StyledValueBlock from "./ValueBlock.styles.js";

type ValueBlockProps = {
    label: string;
    value: string | number;
    children?: React.ReactNode;
};

const ValueBlock: React.FC<ValueBlockProps> = ({
    label,
    value,
    children,
}: ValueBlockProps) => (
    <StyledValueBlock>
        <div className="label">{label}</div>
        {value && <div className="value">{value}</div>}
        {children}
    </StyledValueBlock>
);

export default ValueBlock;
