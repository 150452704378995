import { Field } from "formik";
import React from "react";
import ErrorElement from "../ErrorElement/ErrorElement.js";
import StyledTextInput from "./TextInput.styles.js";

type TextInputProps = {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  id?: string;
  disabled?: boolean;
  step?: number;
  autocomplete?: string;
  smallHeight?: boolean;
  smallWidth?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput: React.FC<TextInputProps> = ({
  name,
  placeholder,
  label,
  type,
  id,
  step,
  autocomplete,
  disabled,
  smallHeight,
  smallWidth,
}: TextInputProps) => {
  return (
    <StyledTextInput smallHeight={smallHeight} smallWidth={smallWidth}>
      {label && <label>{label}</label>}
      <Field
        id={id}
        name={name}
        placeholder={placeholder}
        type={type ? type : "input"}
        step={step}
        autoComplete={autocomplete}
        disabled={disabled}
      />
      <ErrorElement name={name} />
    </StyledTextInput>
  );
};

export default TextInput;
