import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledHourlyPresence = styled.div<{ plotlyheight: number }>`
  ${StyledBlockWrapper} {
    //max-width: 390px;

    table {
      margin-top: 16px;
      min-width: 100%;
      table-layout: fixed;

      th,
      td {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.4px;
        text-align: center;
      }

      td {
        width: 1%;
      }

      tbody td.value {
        font-variant-numeric: tabular-nums;
      }
    }

    .title {
      ${blockTitle};
      // font-size: ${cssVars.fontSizes.large};
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid ${cssVars.colors.grayLight};
    }

    .js-plotly-plot {
      width: 100%;
      height: ${(props) => props.plotlyheight * 20 + 40}px;
    }
  }
`;

export default StyledHourlyPresence;
