import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import { typoDashboardFont } from "../../../styles/cssTypography.js";
import cssVars from "../../../styles/cssVars.js";
import StyledDistanceBlock from "../../atoms/DistanceBlock/DistanceBlock.styles.js";

const StyledMiniInfoCardCapacity = styled.div`
  ${typoDashboardFont};

  background-color: ${cssVars.colors.white};
  width: 100%;

  .title {
    ${blockTitle};
  }

  .icon {
    color: ${cssVars.colors.green};
  }

  .titleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid ${cssVars.colors.grayLight};
  }

  .countWrapper {
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .count {
      font-size: 48px;
      line-height: 60px;
      font-weight: 700;
    }

    ${StyledDistanceBlock} {
      align-self: flex-end;
    }
  }
`;

export default StyledMiniInfoCardCapacity;
