import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeviceData } from "../../../api/v1/Device/device.js";
import { useDevices } from "../../../hooks/api.js";
import { Col, Row } from "../../../styles/Grid.js";
import useWebSocket from "../../../websockets/useWebSocket.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledDeviceList from "./DeviceList.styles.js";

type DeviceListProps = {
    locationId?: string;
    initialDevices?: DeviceData[];
};

type DeviceProps = {
    device: DeviceData;
};

type StatusStamp = {
    status: string;
    stamp: number;
};

const Device: React.FC<DeviceProps> = ({ device }: DeviceProps) => {
    const [status, setStatus] = useState<StatusStamp>({status: "none", stamp: 0});
    const methodName = useMemo(() =>
        `heartbeat-${device.identifier}`
    , [device]);
    const methodFunction = useCallback((message: string) => {
        //console.log(message);
        setStatus({status:"ok", stamp: Date.now()});
    }, []);
    useWebSocket(methodName, methodFunction);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setStatus({status:"none", stamp: Date.now()});
        }, 5 * 60 * 1000);
        return () => {
            clearTimeout(timerId);
        }
    }, [status]);

    return (
        <div className={`deviceStatus ${status.status}`}></div>
    );
};

const DeviceList: React.FC<DeviceListProps> = ({
    locationId,
    initialDevices,
}: DeviceListProps) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { data: devices } = useDevices({
        spaceId: locationId,
        enabled: locationId && locationId.length > 0,
        initialData: initialDevices,
    });

    return (
        <StyledDeviceList>
            <BlockWrapper>
                <div className="title">{t("devicesList.header.title")}</div>
                {(devices ?? []).map((device) => (
                    <div key={`device-${device.identifier}`}>
                        <Row>
                            <Col cols={{ xx: 11 / 12 }}>{device.name}</Col>
                            <Col cols={{ xx: 1 / 12 }}>
                                <Device device={device} />
                            </Col>
                        </Row>
                    </div>
                ))}
            </BlockWrapper>
        </StyledDeviceList>
    );
};

export default DeviceList;
