"use client";

import React from "react";

import {
  BarChart as TremorBarChart,
  type BarChartEventProps,
} from "@/components/BarChart";

export default function BarChart({
  title,
  desc,
  index = "name",
  categories,
  data,
}: {
  title?: string;
  desc?: string;
  index?: string;
  categories: string[];
  data: ({ [key: string]: number } & {
    name: string;
  })[];
}) {
  const [value, setValue] = React.useState<BarChartEventProps>(null);

  return (
    <section className="p-4 shadow-box rounded-md bg-white">
      {title && (
        <header className="space-y-1">
          <h2 className="text-2xl font-medium !mb-0">{title}</h2>
          {desc && <p className="text-sm text-gray-500">{desc}</p>}
        </header>
      )}

      <TremorBarChart
        data={data}
        index={index}
        categories={categories}
        yAxisWidth={45}
        onValueChange={(v) => setValue(v)}
        className="h-80"
      />
    </section>
  );
}
