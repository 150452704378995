import axios from "axios";
import { API_ENDPOINT } from "../constants.js";
import AuthService from "../services/AuthService.js";

axios.defaults.baseURL = API_ENDPOINT;

axios.interceptors.request.use(function (config) {
  const token = AuthService.getAuthToken();
  config.headers.Authorization = token ? `bearer ${token}` : "";
  return config;
});

export default axios;
