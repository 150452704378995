import styled from "styled-components";

const OccupancyBadgeStyle = styled.div<{ backgroundColor: string }>`
  padding: 4px 24px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 100px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export default OccupancyBadgeStyle;
