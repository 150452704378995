import styled from "styled-components";

const StyledSearchDropdown = styled.div`
  label,
  label + select {
    display: block;
  }

  label {
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 5px;
  }
`;

export default StyledSearchDropdown;
