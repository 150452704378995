import { i18n } from "../../utils/i18n.js";
import en from "../en.js";
import nl from "../nl.js";

export function getLocales() {
    return [
        {
            language: "en",
            translation: en.translation,
        },
        {
            language: "nl",
            translation: nl.translation,
        },
    ];
}

export function getLocale(key: string) {
    const locales = getLocales();
    return locales.find((item) => item.language == key);
}

export function getCurrentLanguageTranslation() {
    const foundLocale = getLocale(i18n.language);

    return foundLocale ? foundLocale.translation : en.translation;
}
