import styled from "styled-components";
import cssVars from "../../../styles/cssVars.js";

const StyledValueBlock = styled.div`
  .label {
    font-size: ${cssVars.fontSizes.default};
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid ${cssVars.colors.grayLight};
  }

  .value {
    font-weight: 700;
    font-size: ${cssVars.fontSizes.medium};
  }
`;

export default StyledValueBlock;
