import { ReactNode } from "react";
import GlobalStyle from "../styles/globalStyle.js";

interface UnauthorizedLayoutProps {
    children: ReactNode;
}

export default ({ children }: UnauthorizedLayoutProps) => (
    <>
        <GlobalStyle />
        {children}
    </>
);

import React from "react";
