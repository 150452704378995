import styled from "styled-components";
import cssVars from "../../../../styles/cssVars.js";
import StyledDistanceBlock from "../../../atoms/DistanceBlock/DistanceBlock.styles.js";

const StyledFloorRow = styled.div`
  .floorWrapper {
    display: flex;
    justify-content: flex-end;

    position: relative;

    height: 55px;

    color: ${cssVars.colors.blueLight};

    a {
      color: ${cssVars.colors.blueLight};
      &:hover {
        text-decoration: underline;
      }
    }

    ${StyledDistanceBlock} {
      position: absolute;

      //top: 90px;
      top: 40px;
      left: 10px;

      transform: scale(0.7);

      > div {
        border-radius: 50%;
      }
    }

    h1 {
      position: absolute;

      //top: 100px;
      top: 50px;
      left: 100px;
    }

    h2 {
      position: absolute;
      //top: 80px;
      top: 30px;
      left: 160px;
    }

    .counter {
      position: absolute;
      top: 80px;
      left: 160px;

      font-family: ${cssVars.fonts.default};
      font-size: ${cssVars.fontSizes.large};
    }

    .line {
      position: absolute;

      background-color: ${cssVars.colors.grayLight};

      height: 2px;

      //top: 120px;
      top: 70px;
      left: 160px;
      right: calc(50% + 120px);

      z-index: 300;
    }

    .floor {
      position: absolute;
      //margin-bottom: -160px;
      //margin-bottom: -220px;
      //transform: translateX(100px);
      //transform: translateX(50%) scale(0.5);
      //transform: translateY(-50px) scale(0.5);
      transform: translateY(-25px) scale(0.5);
      left: calc(50% - 150px);
      height: 180px;

      .blue {
        background: ${cssVars.colors.blue};
      }

      .red {
        background: ${cssVars.colors.red};
      }

      .orange {
        background: ${cssVars.colors.orange};
      }

      .yellow {
        background: ${cssVars.colors.yellow};
      }

      .green {
        background: ${cssVars.colors.green};
      }

      .floorBase {
        //background-color: ${cssVars.colors.green};

        width: 300px;
        height: 200px;

        box-shadow: #ddd -33px 52px 24px;
        opacity: 0.8;

        //transform: translateX(100px) translateY(0px) scaleY(0.6) rotateZ(-35deg);
        transform: translateX(0) translateY(0px) scaleY(0.6) rotateZ(-35deg);
      }

      .floorSide1 {
        background-color: ${cssVars.colors.grayLight};

        width: 300px;
        height: 50px;

        //transform: translateX(157px) translateY(-58px) scaleY(0.6) rotateZ(-35deg) skewX(-35deg);
        transform: translateX(57px) translateY(-58px) scaleY(0.6)
          rotateZ(-35deg) skewX(-35deg);
      }

      .floorSide2 {
        background-color: ${cssVars.colors.gray};

        width: 200px;
        height: 35px;

        //transform: translateX(27px) translateY(-98px) scaleY(0.6) rotateZ(55deg) skewX(55deg);
        transform: translateX(-73px) translateY(-98px) scaleY(0.6)
          rotateZ(55deg) skewX(55deg);
      }

      &:hover {
        opacity: 0.5;
      }
    }

    &.odd {
      ${StyledDistanceBlock} {
        left: auto;
        right: 10px;
      }

      h1 {
        left: auto;
        right: 100px;
      }

      h2 {
        left: auto;
        right: 160px;
      }

      .counter {
        left: auto;
        right: 160px;
      }

      .line {
        right: 160px;
        left: calc(50% + 120px);
      }
    }
  }
`;

export default StyledFloorRow;
