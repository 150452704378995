import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CenteredLink from "../../../components/atoms/CenteredLink/CenteredLink.js";
import PasswordForgotten from "../../../components/auth/PasswordForgotten/PasswordForgotten.js";
import AuthService from "../../../services/AuthService.js";
import RESTService from "../../../services/RESTService.js";
import { ForgotPasswordValues } from "../auth.js";
import { defaults, validation } from "./schemaPasswordForgotten.js";

const PasswordForgottenPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const [error, setError] = useState<string>(null);
    const [initialValues, setInitialValues] = useState(defaults);

    useEffect(() => {
        setTimeout(() => {
            const email = AuthService.getAuthTokenEmail();

            if (email) {
                setInitialValues({ email: email });
            }
        }, 500);
    }, []);

    return (
        <StyledPasswordForgottenPage>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(
                    values: ForgotPasswordValues,
                    {
                        setSubmitting,
                        setFieldError,
                    }: FormikHelpers<ForgotPasswordValues>,
                ) => {
                    void RESTService.postForm({
                        url: "Auth/ForgetPassword",
                        payload: values,
                        successCallback(res: AxiosResponse): void {
                            history("/password-forgotten/thankyou");
                        },
                        setSubmitting: setSubmitting,
                        setError: setError,
                        setFieldError: setFieldError,
                    });
                }}
            >
                {({ isSubmitting }) => (
                    <PasswordForgotten submit={isSubmitting} error={error} />
                )}
            </Formik>
            <CenteredLink to="/login" title={t("auth.login.backToLogin")} />
            {/*<Link to="/login">{t('auth.login.backToLogin')}</Link>*/}
        </StyledPasswordForgottenPage>
    );
};

const StyledPasswordForgottenPage = styled.div``;

export default PasswordForgottenPage;
