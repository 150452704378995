import React from "react";
import { useTranslation } from "react-i18next";
import type usePeakOccupancy from "../../../hooks/usePeakOccupancy.js";
import cssVars from "../../../styles/cssVars.js";
import { hexToRgb } from "../../../utils/colors.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledHourlyPresence from "./HourlyPresence.styles.js";

type HourlyPresenceProps = {
  data: ReturnType<typeof usePeakOccupancy>;
};

const HourlyPresence: React.FC<HourlyPresenceProps> = ({
  data: {
    x: dayLabels,
    y: hourLabels,
    z: hourValuePerDay,
    maxZ: maxPresenceInPeriod,
  },
}: HourlyPresenceProps) => {
  const { t } = useTranslation();

  return (
    <StyledHourlyPresence plotlyheight={hourLabels.length}>
      <BlockWrapper>
        <div className="title">{t("dashboard.HourlyPresence.header")}</div>
        <table>
          <thead>
            <tr>
              <th scope="col">Time</th>
              {dayLabels.map((dayLabel) => (
                <th
                  key={dayLabel}
                  scope="col"
                >{`${dayLabel[0].toUpperCase()}${dayLabel.slice(1)}`}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {hourLabels.map((hourLabel, hourIndex) => {
              return (
                <tr key={hourLabel}>
                  <td className="dayLabel">{hourLabel}</td>
                  {dayLabels.map((dayLabel, dayIndex) => {
                    const value = Math.max(
                      hourValuePerDay[hourIndex]?.[dayIndex] || 0,
                      0,
                    );

                    // We want to calculate the color of the cell based on the max value in the period
                    // This is to make sure that the color scale is consistent across all the tables
                    const rgbColor = hexToRgb(cssVars.colors.green);
                    const opacity = value / maxPresenceInPeriod;

                    return (
                      <td
                        className="value"
                        key={`${dayLabel}-${opacity}`}
                        style={{
                          backgroundColor: `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${opacity})`,
                        }}
                      >
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </BlockWrapper>
    </StyledHourlyPresence>
  );
};

export default HourlyPresence;
