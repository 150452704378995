import styled from "styled-components";
import { blockTitle } from "../../../styles/block.js";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";

const StyledHeatmap = styled.div`
  ${StyledBlockWrapper} {
    .title {
      ${blockTitle};
      // font-size: ${cssVars.fontSizes.large};
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid ${cssVars.colors.grayLight};
      margin-bottom: 16px;
    }
  }
`;

export default StyledHeatmap;
