import { Buffer } from "buffer";
import { useFormikContext } from "formik";
import { getOrientation } from "get-orientation/browser";
import React, { useCallback, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Image } from "../../../api/v1/image.js";
import { Col, Row } from "../../../styles/Grid.js";
import { getCroppedImg, getRotatedImage } from "../../../utils/canvasUtils.js";
import Icon from "../../atoms/Icon/Icon.js";
import ErrorElement from "../ErrorElement/ErrorElement.js";
import StyledCroppedFileUpload from "./CroppedFileUpload.styles.js";
import FileUpload from "./FileUpload/FileUpload.js";

const ORIENTATION_TO_ANGLE = {
  "3": 180,
  "6": 90,
  "8": -90,
};

const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];
const ALLOWED_MAX_FILE_SIZE = 5000000;

type CroppedFileUploadProps = {
  name: string;
  image: Image;
  deleteImageEvent?: () => void;
  label?: string;
};

/**
 * Based on:
 * https://www.npmjs.com/package/react-easy-crop
 * https://codesandbox.io/s/y09komm059?file=/src/index.js:4047-4254
 * */
const CroppedFileUpload: React.FC<CroppedFileUploadProps> = ({
  name,
  image,
  deleteImageEvent,
  label,
}: CroppedFileUploadProps) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  const [cropping, setCropping] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);

  useEffect(() => {
    if (!image) return;
    setCroppedImage(image?.url);
    void asyncSetImageSrc(image?.url);
  }, [image]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );
      setCroppedImage(croppedImage);
      await asyncSetCroppedImage(croppedImage);
      setCropping(!cropping);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const asyncSetCroppedImage = async (croppedImage) => {
    await fetch(croppedImage)
      .then((response: Response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64String = reader.result;
          formik.setFieldValue(name, base64String);
        };
      });
  };

  const asyncSetImageSrc = async (imageSource: string) => {
    if (!imageSource) return;

    await fetch(imageSource)
      .then((response: Response) => response.arrayBuffer())
      .then((blob) => {
        const bufferResult = Buffer.from(blob).toString("base64");
        if (!bufferResult) return;

        //https://stackoverflow.com/a/27887492/2459761
        let extension = undefined;
        // do something like this
        const lowerCase = imageSource.toLowerCase();
        if (lowerCase.indexOf("png") !== -1) extension = "png";
        else if (
          lowerCase.indexOf("jpg") !== -1 ||
          lowerCase.indexOf("jpeg") !== -1
        )
          extension = "jpg";
        else extension = "tiff";

        const data = `data:image/${extension};base64,${Buffer.from(
          blob,
        ).toString("base64")}`;
        setImageSrc(data);
      });
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onFileChange = async (file: File) => {
    if (ALLOWED_IMAGE_TYPES.indexOf(file.type) < 0) {
      alert(t("croppedFileUpload.errors.fileType"));
      return;
    }
    if (file.size > ALLOWED_MAX_FILE_SIZE) {
      alert(
        t("croppedFileUpload.errors.fileSize", {
          size: (ALLOWED_MAX_FILE_SIZE / 1000000).toFixed(1),
        }),
      );
      return;
    }

    let imageDataUrl = await readFile(file);

    // apply rotation if needed
    const orientation = await getOrientation(file);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
    }

    setImageSrc(imageDataUrl);
    setCroppedImage(imageDataUrl);
    await asyncSetCroppedImage(imageDataUrl);
  };

  const deleteImage = () => {
    setImageSrc(null);
    setCroppedImage(null);

    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
    setCroppedAreaPixels(null);

    formik.setFieldValue(name, null);
    if (deleteImageEvent) {
      deleteImageEvent();
    }
  };

  return (
    <StyledCroppedFileUpload>
      <Row>
        {label && <label>{label}</label>}
        {croppedImage && !cropping ? (
          <React.Fragment>
            <Col>
              <Row>
                <img src={croppedImage} />
              </Row>
              <Row>
                {/*<Button label={t('croppedFileUpload.deleteButton')} onClick={deleteImage} dangerButton />*/}
                {/*{imageSrc && (*/}
                <Icon
                  name="crop"
                  className="goodCropping"
                  size={18}
                  click={() => {
                    setCropping(!cropping);
                  }}
                />
                {/*)}*/}
                <Icon
                  name="x"
                  className="badCropping"
                  size={18}
                  click={deleteImage}
                />
              </Row>
            </Col>
          </React.Fragment>
        ) : imageSrc && cropping ? (
          <React.Fragment>
            <div className="cropContainer">
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div>
              <Icon
                name="check"
                className="goodCropping"
                click={showCroppedImage}
                size={16}
              />
              <Icon name="x" className="badCropping" size={18} />
            </div>
          </React.Fragment>
        ) : (
          <FileUpload handleFile={onFileChange} />
        )}
      </Row>
      <ErrorElement name={name} />
    </StyledCroppedFileUpload>
  );
};

export default CroppedFileUpload;
