import { motion } from "framer-motion";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../../styles/Grid.js";
import cssVars from "../../../styles/cssVars.js";
import useWebSocketSetter from "../../../websockets/useWebSocketSetter.js";
import { LiveEventData } from "../../../websockets/websockets.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import ValueBlock from "../../atoms/ValueBlock/ValueBlock.js";
import StyledLiveEvents from "./LiveEvents.styles.js";

type LiveEventsProps = {
    locationIdentity: string;
    targetCapacity: number;
};

type PointProps = {
    duration: number;
    removeMe: () => void;
    top: string;
    val: number;
};

type ArrayPoint = {
    key: string;
    y: string;
    val: number;
};

const Point: React.FC<PointProps> = ({
    duration,
    removeMe,
    top,
    val,
}: PointProps) => {
    const [hidden, setHidden] = useState<boolean>(false);
    let startColor = cssVars.colors.green;
    let endColor = cssVars.colors.blue;

    if (val < 0) {
        startColor = cssVars.colors.orange;
        endColor = cssVars.colors.red;
    }

    if (hidden) {
        return <></>;
    }

    return (
        <motion.div
            className="point"
            initial={{
                left: "calc(100% - 5px)",
                top: top,
                background: startColor,
            }}
            animate={{
                left: "2%",
                background: endColor,
            }}
            transition={{ duration: duration, ease: "linear" }}
            onAnimationComplete={(definition) => {
                setHidden(true);
                removeMe();
            }}
        >
            <div>{val}</div>
        </motion.div>
    );
};

//TODO: see https://plotly.com/javascript/streaming/
const LiveEvents: React.FC<LiveEventsProps> = ({
    locationIdentity,
    targetCapacity,
}: LiveEventsProps) => {
    const { t } = useTranslation();
    const [occupancy, setOccupancy] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);
    const [pointArray, setPointArray] = useState<ArrayPoint[]>([]);

    const MAXRANGE = 10;
    const minimumInterval = 200; //ms
    const timeMax = 15; //in s
    const maxPoints = (timeMax * 1000) / minimumInterval;

    const methodName = useMemo(() =>
        `liveevents-${locationIdentity}`
    , [locationIdentity]);

    const addPoint = (inVal: number, outVal: number) => {
        let newInPoint = null;
        let newOutPoint = null;
        if (inVal > 0) {
            newInPoint = {
                key: Math.random().toString().substr(2, 8),
                y: calculateTop(inVal),
                val: inVal,
            };
        }

        if (outVal < 0) {
            newOutPoint = {
                key: Math.random().toString().substr(2, 8),
                y: calculateTop(outVal),
                val: outVal,
            };
        }

        setPointArray((pointArray): ArrayPoint[] => {
            return [
                newInPoint,
                newOutPoint,
                ...pointArray.slice(0, maxPoints),
            ] as ArrayPoint[];
        });
    };

    const methodFunction = useCallback((data) => {
        addPoint(data.Entries, -data.Exits);

        setOccupancy(data.CurrentOccupancy);
        if (data.MaxOccupancy > 0) {
            setPercentage((data.CurrentOccupancy / data.MaxOccupancy) * 100);
        } else {
            setPercentage(0);
        }
    }, []);
    useWebSocketSetter<LiveEventData>(methodName, methodFunction);

    const removeLastPoint = (key) => {
        setPointArray((pointArray) =>
            pointArray.slice(0, pointArray.length - 1),
        );
    };

    const calculateTop = (y): string => {
        if (Math.abs(y) < 1) {
            return "50%";
        }

        const outcome =
            50 -
            Math.sign(y) * (Math.log10((Math.abs(y) / MAXRANGE) * 10) * 30 + 5);

        if (outcome < -10) {
            return "-10%";
        }
        if (outcome > 110) {
            return "110%";
        }

        return outcome.toString() + "%";
    };

    return (
        <StyledLiveEvents>
            <BlockWrapper>
                <Row>
                    <Col cols={{ xs: 2 / 4 }}>
                        <div className="title">
                            {t("dashboard.liveEvents.header")}
                        </div>
                    </Col>
                    <Col cols={{ xs: 1 / 4 }}>
                        <ValueBlock
                            label={t("dashboard.liveEvents.numberPeople")}
                            value={occupancy}
                        />
                    </Col>
                    <Col cols={{ xs: 1 / 4 }}>
                        <ValueBlock
                            label={t("dashboard.liveEvents.filled")}
                            value={`${percentage.toFixed(2)} %`}
                        />
                    </Col>
                </Row>
                <div className="liveLineWrapper">
                    <div className="pointWrapper">
                        <div className="liveLine"></div>
                        {pointArray.map(
                            (point: ArrayPoint) =>
                                point && (
                                    <Point
                                        key={point.key}
                                        duration={timeMax}
                                        top={point.y}
                                        val={point.val}
                                        removeMe={() => {
                                            removeLastPoint(point);
                                        }}
                                    />
                                ),
                        )}
                    </div>
                </div>
            </BlockWrapper>
        </StyledLiveEvents>
    );
};

export default LiveEvents;
