export default function mergeDefaultValues<FormType, ValueType>(defaults: FormType, values: ValueType): FormType {
    const keys = Object.keys(defaults);

    return Object.fromEntries(keys.map((key) => {
        const def = defaults[key];
        const val = values[key];
        if (typeof def === "object")
            return [key, mergeDefaultValues(def, val)];
        else if (val === undefined || val === null)
            return [key, def];
        else
            return [key, val];
    })) as FormType;
}
