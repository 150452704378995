import styled from "styled-components";
import { defaultInputStyle } from "../../../styles/cssInput.js";
import getSVGUrl from "../../../styles/helpers/getSVG.js";

const StyledDropdown = styled.div`
  position: relative;

  label,
  label + select {
    display: block;
  }

  label {
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 5px;
  }

  select {
    ${defaultInputStyle};

    width: 100%;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:after {
    content: "";

    background: ${getSVGUrl("arrowdown")};
    display: block;

    position: absolute;
    right: 12px;
    bottom: 20px;

    width: 14px;
    height: 8px;

    pointer-events: none;
  }
`;

export default StyledDropdown;
