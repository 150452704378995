import styled from "styled-components";
import StyledButton from "../../UI/Button/Button.styles.js";

const StyledTableBasic = styled.div`
  ${StyledButton} {
    button {
      height: 25px;
      line-height: 20px;
      padding: 0 16px;
    }
  }
`;

export default StyledTableBasic;
