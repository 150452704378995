import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

import Grid from "~/components/UI/Grid/Grid.js";
import BlockWrapper from "~/components/atoms/BlockWrapper/BlockWrapper.js";
import { useSpacePseudogateVisitorAverages } from "~/hooks/api.js";

type VisitorAveragesProps = {
  pseudoGateId: string;
};

const VisitorAverages: React.FC<VisitorAveragesProps> = ({ pseudoGateId }) => {
  const queryClient = useQueryClient();
  const { id: spaceId } = useParams<{ id: string }>();

  const { data } = useSpacePseudogateVisitorAverages({
    spaceId,
    pseudogateId: pseudoGateId,
    queryClient,
  });

  if (!data) return null;

  const isNumber = (value: any): value is number => typeof value === "number";

  return (
    <Grid gap={16} numberOfColumns={3}>
      {isNumber(data?.global) && (
        <BlockWrapper>
          <h3 className="text-base pb-0.5 leading-tight">
            Average daily visitors
          </h3>
          <div className="text-4xl font-bold tabular-nums">
            {parseFloat(data.global.toFixed(1))}
          </div>
        </BlockWrapper>
      )}
      {isNumber(data?.weekdays) && (
        <BlockWrapper>
          <h3 className="text-base pb-0.5 leading-tight">
            Average weekday visitors
          </h3>
          <div className="text-4xl font-bold tabular-nums">
            {parseFloat(data.weekdays.toFixed(1))}
          </div>
        </BlockWrapper>
      )}
      {isNumber(data?.weekends) && (
        <BlockWrapper>
          <h3 className="text-base pb-0.5 leading-tight">
            Average weekend visitors
          </h3>
          <div className="text-4xl font-bold tabular-nums">
            {parseFloat(data.weekends.toFixed(1))}
          </div>
        </BlockWrapper>
      )}
    </Grid>
  );
};

export default VisitorAverages;
