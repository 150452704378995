import { object, string } from "yup";
import { getCurrentLanguageTranslation } from "../../../locales/helpers/index.js";

export const defaults = {
    email: "",
};

const { validationRules: msg } = getCurrentLanguageTranslation();

export const validation = object().shape({
    email: string().email(msg.string.email).required(msg.mixed.required),
});
