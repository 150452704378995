import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PresenceData, UniqueCounterData } from "~/api/v1/ReportData/index.js";
import BlockWrapper from "~/components/atoms/BlockWrapper/BlockWrapper.js";
import AreaChart from "~/components/Dashboard/AreaChart/AreaChart.js";
import HeatMap from "~/components/Dashboard/HeatMap/HeatMap.js";
import HourlyPresence from "~/components/Dashboard/HourlyPresence/HourlyPresence.js";
import OccupancyBadge from "~/components/Dashboard/OccupancyBadge/OccupancyBadge.js";
import StatsCards from "~/components/Dashboard/StatsCards/StatsCards.js";
import VisitorAverages from "~/components/Dashboard/VisitorAverages/VisitorAverages.js";
import VisitorsPerDay from "~/components/Dashboard/VisitorsPerDay/VisitorsPerDay.js";
import VisitorsPerWeek from "~/components/Dashboard/VisitorsPerWeek/VisitorsPerWeek.js";
import ZoneList from "~/components/Dashboard/ZoneList/ZoneList.js";
import StyledDashboard from "~/components/pages/Dashboard/Dashboard.styles.js";
import Header from "~/components/portal/HeaderNew/Header.js";
import ArrowButton from "~/components/UI/ArrowButton/ArrowButton.js";
import Button from "~/components/UI/Button/Button.js";
import DateSliderWeek from "~/components/UI/DateSliderWeek/DateSliderWeek.js";
import Grid from "~/components/UI/Grid/Grid.js";
import { UserRole } from "~/constants.js";
import {
  useSpace,
  useSpacePresenceZones,
  useSpacePresenceZonesLatestData,
  useSpacePseudogates,
  useSpacePseudogatesLatestData,
  useSpaceUniqueCounters,
  useSpaceUniqueCountersLatestData,
} from "~/hooks/api.js";
import usePeakPresence from "~/hooks/usePeakPresence.js";
import useUniqueWeekCountsHack from "~/hooks/useUniqueWeekCountsHack.js";
import {
  useWebSocketUpdatedMultiQuery,
  useWebSocketUpdatedQuery,
} from "~/hooks/websocketQuery.js";
import AuthService from "~/services/AuthService.js";
import { startOfWeek } from "~/utils/date-time.js";

type TotaledData = {
  total: number;
  person: number;
  runner: number;
  bike: number;
};

const LocationsAreaDashboardPage: React.FC = () => {
  const now = new Date().valueOf();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { id: spaceId } = useParams<{ id: string }>();
  const spacePHAOptions = { spaceId, queryClient, placeholderData: [] };
  const spacePHOOptions = { spaceId, queryClient, placeholderData: {} };

  const { data: locationData } = useSpace({ spaceId });
  const { data: pseudogates } = useSpacePseudogates(spacePHAOptions);

  const organizationId = locationData?.organization?.identifier;

  const { data: presenceZones } = useSpacePresenceZones({ spaceId });
  const zoneIds = useMemo(() => {
    return Object.values(presenceZones ?? {}).map((zone) => zone.identifier);
  }, [presenceZones]);

  const { data: uniqueCounters } = useSpaceUniqueCounters({ spaceId });

  const { data: presenceZoneData, queryKey: presenceQuery } =
    useSpacePresenceZonesLatestData({
      spaceId,
      queryClient,
    });

  const { data: pseudogateData, queryKey: pseudogateQuery } =
    useSpacePseudogatesLatestData(spacePHOOptions);

  const presencePrefix = useMemo(() => `presence-${spaceId}`, [spaceId]);
  useWebSocketUpdatedMultiQuery<PresenceData>({
    queryKey: presenceQuery,
    groupPrefix: presencePrefix,
    items: zoneIds,
    queryClient,
    organizationId,
  });

  const { data: uniqueCounterData, queryKey: uniqueQuery } =
    useSpaceUniqueCountersLatestData({ spaceId });

  const uniqueMethod = useMemo(() => `uniquecounter-${spaceId}`, [spaceId]);
  useWebSocketUpdatedQuery<UniqueCounterData>({
    queryKey: uniqueQuery,
    methodName: uniqueMethod,
    queryClient,
    organizationId,
  });

  const canEdit = AuthService.hasRole(UserRole.Admin);

  const presenceZoneDataCombined = useMemo(() => {
    const values = Object.values(presenceZoneData ?? {});

    return values.reduce<TotaledData>(
      (acc, presenceData) => {
        if (!presenceData) return acc;

        const person = presenceData.person ?? 0;
        const runner = presenceData.runner ?? 0;
        const bike = presenceData.bike ?? 0;

        return {
          person: acc.person + person,
          runner: acc.runner + runner,
          bike: acc.bike + bike,
          total: acc.total + person + runner + bike,
        };
      },
      { person: 0, runner: 0, bike: 0, total: 0 },
    );
  }, [presenceZoneData]);

  const startDateOfCurrentWeek = startOfWeek(now).toISOString();

  const [startDateSelectedWeek, setStartDateSelectedWeek] = useState(
    startDateOfCurrentWeek,
  );

  const peakPresence = usePeakPresence({
    spaceId: spaceId,
    startOfWeekDate: startDateSelectedWeek,
  });

  const dataUniqueCountPerCategory = useUniqueWeekCountsHack({
    spaceId,
    startOfWeekDate: startDateSelectedWeek,
  });

  const wholePseudoGateId =
    pseudogates.length === 1
      ? pseudogates[0].identifier
      : (pseudogates.find((obj) => obj.name === "whole-field") || {})
          .identifier ?? undefined;

  if (!locationData) return <></>;

  return (
    <>
      <Header>
        <div
          className="flex flex-row items-center"
          style={{ columnGap: "24px" }}
        >
          <ArrowButton
            onClick={() => {
              void 0;
            }}
            mirror={true}
          />
          <h2>
            {[locationData.name] // api doesn't actually provide city
              .filter(Boolean)
              .join(" | ")}
          </h2>
        </div>

        <div
          className="flex flex-row items-center"
          style={{ columnGap: "16px" }}
        >
          {false && (
            <OccupancyBadge
              currentOccupancy={
                0 /*currentCounterStateCombined.occupation.current*/
              }
            />
          )}

          {canEdit && (
            <Button
              label={t("locations.dashboard.header.button")}
              url={"/locations/" + spaceId + "/edit"}
            />
          )}

          <ArrowButton
            onClick={() => {
              void 0;
            }}
          />
        </div>
      </Header>

      <StyledDashboard>
        <Grid
          gap={16}
          numberOfColumns={{
            xl: 3,
          }}
        >
          <Grid.Cell
            className="infoCards"
            columnSpan={{
              xl: 2,
            }}
          >
            <DateSliderWeek
              onChange={(date) => {
                setStartDateSelectedWeek(startOfWeek(date).toISOString());
              }}
            />

            {wholePseudoGateId && (
              <VisitorAverages pseudoGateId={wholePseudoGateId} />
            )}

            {wholePseudoGateId && (
              <VisitorsPerWeek pseudoGateId={wholePseudoGateId} />
            )}

            {wholePseudoGateId && (
              <VisitorsPerDay
                pseudoGateId={wholePseudoGateId}
                startDate={startDateSelectedWeek}
              />
            )}

            {uniqueCounters?.length > 0 ? (
              <AreaChart
                type="stacked"
                title="Category Count"
                // @ts-expect-error TODO: fix types
                data={dataUniqueCountPerCategory.data}
                categories={dataUniqueCountPerCategory.categories}
              />
            ) : null}

            <Grid gap={16}>
              {peakPresence && <HourlyPresence data={peakPresence} />}
            </Grid>

            <HeatMap />
          </Grid.Cell>
          <Grid.Cell>
            <Grid gap={16}>
              <BlockWrapper>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    marginBottom: 0,
                  }}
                >
                  Live Events
                </h2>
              </BlockWrapper>

              <StatsCards
                presenceZoneDataCombined={presenceZoneDataCombined}
                uniqueCounterData={uniqueCounterData}
              />

              <ZoneList organizationId={organizationId} spaceId={spaceId} />
            </Grid>
          </Grid.Cell>
        </Grid>
      </StyledDashboard>
    </>
  );
};

export default LocationsAreaDashboardPage;
