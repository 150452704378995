import styled, { css } from "styled-components";

type Props = {
  size?: number;
};

const StyledIcon = styled.div<Props>`
  ${(props) =>
    props.size &&
    css`
      svg {
        width: ${props.size}px;
        height: ${props.size}px;
      }
    `}
`;

export default StyledIcon;
