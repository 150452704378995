import { useCallback } from "react";
import useWebSocket from "./useWebSocket.js";

export default function useWebSocketSetter<DataType extends object>(
    methodName: string | null,
    setterFunction: (newData: DataType) => void,
    organizationId?: string,
) {
    const methodFunction = useCallback((message: string) => {
        const data = JSON.parse(message) as DataType;
        setterFunction(data);
    }, [setterFunction]);
    useWebSocket(methodName, methodFunction, organizationId);
}
