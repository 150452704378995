import React from "react";
import StyledLogo from "./Logo.styles.js";

type LogoProps = {
    //
};

const Logo: React.FC<LogoProps> = () => (
    <StyledLogo>
        <img src={"/images/husense_logo.svg"} />
    </StyledLogo>
);

export default Logo;
