import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CenteredLink from "../../../components/atoms/CenteredLink/CenteredLink.js";
import Login from "../../../components/auth/Login/Login.js";
import AuthService from "../../../services/AuthService.js";
import RESTService from "../../../services/RESTService.js";
import { LoginData, LoginValues } from "../auth.js";
import { defaults, validation } from "./schema.js";

const LoginPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [error, setError] = useState<string>(null);

    //check if email has been verified
    const emailVerified = query.has("emailverified");

    return (
        <StyledLoginPage>
            <Formik
                initialValues={defaults}
                validationSchema={validation}
                onSubmit={(
                    values: LoginValues,
                    {
                        setSubmitting,
                        setFieldError,
                    }: FormikHelpers<LoginValues>,
                ) => {
                    void RESTService.postForm<LoginData>({
                        url: "Auth/Login",
                        payload: values,
                        successCallback(res: AxiosResponse<LoginData>): void {
                            AuthService.setAuthToken(res.data.access_token);
                            AuthService.setRefreshToken(res.data.refresh_token);
                            window.location.href = "/";
                        },
                        setSubmitting: setSubmitting,
                        setError: setError,
                        setFieldError: setFieldError,
                    });
                }}
            >
                {({ isSubmitting }) => (
                    <Login
                        submit={isSubmitting}
                        error={error}
                        emailConfirmed={emailVerified}
                    />
                )}
            </Formik>
            <CenteredLink
                to="/password-forgotten"
                title={t("auth.login.forgotPassword")}
            />
        </StyledLoginPage>
    );
};

const StyledLoginPage = styled.div``;

export default LoginPage;
