import React from "react";
import Logo from "../../atoms/Logo/Logo.js";
import StyledMessagePage from "./MessagePage.styles.js";

type MessagePageProps = {
    title: string;
    message: string;
};

const MessagePage: React.FC<MessagePageProps> = ({
    title,
    message,
}: MessagePageProps) => (
    <StyledMessagePage>
        <Logo />
        <h1>{title}</h1>
        <p>{message}</p>
    </StyledMessagePage>
);

export default MessagePage;
