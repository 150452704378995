import styled from "styled-components";
import { cssRulePerBreakpoint } from "../../../styles/breakpoints";
import { BreakpointObjectGrid } from "./Grid.styles";

const StyledColumnWrapper = styled.div<{
  columnSpan: number | BreakpointObjectGrid;
}>`
  ${(props) => {
    const columnSpan = props.columnSpan;

    if (typeof columnSpan === "number") {
      return `grid-column: span ${columnSpan} / span ${columnSpan}`;
    }

    const breakpoints = { ...columnSpan };
    const hasLowestBreakpoint = "xx" in breakpoints;

    if (!hasLowestBreakpoint) {
      breakpoints.xx = 1;
    }

    return cssRulePerBreakpoint(
      breakpoints,
      (val) => `grid-column: span ${val} / span ${val}`,
    );
  }}
`;

export default StyledColumnWrapper;
