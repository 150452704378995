import { createGlobalStyle } from "styled-components";
import cssVars from "./cssVars.js";

//fonts are imported as link tag in the public/index.html and .storybook/preview-head.html
const GlobalStyle = createGlobalStyle`
  //normal
  @font-face {
    font-family: 'Regular';
    src: url('fonts/Regular_-_Regular.otf') format('opentype');
  }

  //all bold
  @font-face {
    font-family: 'Regular';
    font-weight: 900;
    src: url('fonts/Regular_-_Black.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 800;
    src: url('fonts/Regular_-_Extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 700;
    src: url('fonts/Regular_-_Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 600;
    src: url('fonts/Regular_-_Semibold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 500;
    src: url('fonts/Regular_-_Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 400;
    src: url('fonts/Regular_-_Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 300;
    src: url('fonts/Regular_-_Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 200;
    src: url('fonts/Regular_-_Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 100;
    src: url('fonts/Regular_-_Light.otf') format('opentype');
  }
  
  //all italic
  @font-face {
    font-family: 'Regular';
    font-weight: 900;
    font-style: italic;
    src: url('fonts/Regular_-_Black_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 800;
    font-style: italic;
    src: url('fonts/Regular_-_Extrabold_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 700;
    font-style: italic;
    src: url('fonts/Regular_-_Bold_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 600;
    font-style: italic;
    src: url('fonts/Regular_-_Semibold_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 500;
    font-style: italic;
    src: url('fonts/Regular_-_Medium_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 400;
    font-style: italic;
    src: url('fonts/Regular_-_Regular_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 300;
    font-style: italic;
    src: url('fonts/Regular_-_Light_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 200;
    font-style: italic;
    src: url('fonts/Regular_-_Light_Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Regular';
    font-weight: 100;
    font-style: italic;
    src: url('fonts/Regular_-_Light_Italic.otf') format('opentype');
  }
  
  body{
    margin: 0;
    padding: 0;
    
    font-family: ${cssVars.fonts.default};
    color: ${cssVars.colors.black};
  }
  
  *{
    box-sizing: border-box;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5{
    margin-top: 0;
  }
  
  a{
    color: ${cssVars.colors.blue};
    
    &:hover{
      color: ${cssVars.colors.blueLightLight};
    }
  }
`;

export default GlobalStyle;
