import React from "react";
import { Col, Row } from "../../../styles/Grid.js";
import FormWrapper from "../FormWrapper/FormWrapper.js";
import StyledFormColumns from "./FormColumns.styles.js";

type FormColumnsProps = {
    children: React.ReactNode;
};

export const FormWrapperLeft: React.FC<FormColumnsProps> = ({
    children,
}: FormColumnsProps) => {
    return (
        <Col cols={{ md: 8 / 12 }}>
            <FormWrapper>{children}</FormWrapper>
        </Col>
    );
};

export const FormWrapperRight: React.FC<FormColumnsProps> = ({
    children,
}: FormColumnsProps) => {
    return (
        <Col cols={{ md: 4 / 12 }}>
            <FormWrapper>{children}</FormWrapper>
        </Col>
    );
};

const FormColumns: React.FC<FormColumnsProps> = ({
    children,
}: FormColumnsProps) => {
    return (
        <StyledFormColumns>
            <Row>{children}</Row>
        </StyledFormColumns>
    );
};

export default FormColumns;
