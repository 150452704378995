import { object, string } from "yup";
import { getCurrentLanguageTranslation } from "../../../locales/helpers/index.js";

export const defaults = {
    name: "",
    type: "1",
    location_image: "",
    capacity: "",
};

const { validationRules: msg } = getCurrentLanguageTranslation();

export const validation = object().shape({
    name: string().required(msg.mixed.required),
    capacity: string().required(msg.mixed.required),
});
