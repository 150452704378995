import React from "react";
import Cell from "./Cell";
import StyledGridWrapper, { StyledGridWrapperProps } from "./Grid.styles";

const GridWrapper: React.FC<
    {
        children: React.ReactNode;
    } & Partial<StyledGridWrapperProps>
> = ({ children, gap = 0, numberOfColumns = 1 }) => {
    return (
        <StyledGridWrapper numberOfColumns={numberOfColumns} gap={gap}>
            {children}
        </StyledGridWrapper>
    );
};

const Grid = Object.assign(GridWrapper, { Cell });

export default Grid;
