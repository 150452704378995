import React from "react";
import { Link } from "react-router-dom";
import StyledCenteredLink from "./CenteredLink.styles.js";

type CenteredLinkProps = {
    to: string;
    title: string;
};

const CenteredLink: React.FC<CenteredLinkProps> = ({
    to,
    title,
}: CenteredLinkProps) => (
    <StyledCenteredLink>
        <Link to={to}>{title}</Link>
    </StyledCenteredLink>
);

export default CenteredLink;
