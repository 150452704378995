import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeInfo } from "./base.js";
import { DeviceData } from "../../api/v1/Device/device.js";

export interface DevicesOptions {
    orgId?: string;
    spaceId?: string;
    enabled?: boolean;
    initialData?: DeviceData[];
}

export function useDevices({
    orgId,
    spaceId,
    enabled: enabledOption,
    initialData,
}: DevicesOptions): UseQueryResult<DeviceData[]> {
    const enabled = enabledOption === undefined || enabledOption;
    let queryKey;
    if (spaceId === undefined) {
        if (orgId === undefined)
            queryKey = ["Devices"];
        else
            queryKey = ["Devices", "org", orgId];
    } else {
        if (orgId === undefined)
            queryKey = ["Devices", "space", spaceId];
        else
            queryKey = ["Devices", "org", orgId, spaceId];
    }

    return useQuery({
        enabled,
        queryKey,
        queryFn: baseQueryFn<DeviceData[]>,
        staleTime: staleTimeInfo,
        initialData,
    });
}

export interface DeviceOptions {
    deviceId: string;
    enabled?: boolean;
    staleTime?: number;
}

export function useDevice({
    deviceId,
    enabled: enabledOption,
    staleTime,
}: DeviceOptions): UseQueryResult<DeviceData> {
    const queryKey = ["Devices", deviceId];
    const enabled = (enabledOption === undefined || enabledOption)
        && !!deviceId;

    return useQuery({
        enabled,
        queryKey,
        queryFn: baseQueryFn<DeviceData>,
        staleTime: staleTime ?? staleTimeInfo,
    });
}
