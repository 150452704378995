import styled from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import StyledBlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.styles.js";
import StyledInfoBlock from "../../atoms/InfoBlock/InfoBlock.styles.js";

const StyledPasswordForgotten = styled.div`
  width: 100%;

  ${StyledBlockWrapper},
  ${StyledInfoBlock} {
    width: 300px;
  }

  ${StyledInfoBlock} {
    margin: 20px auto;
  }

  ${StyledBlockWrapper} {
    margin: 100px auto 40px;

    h1 {
      margin: 0;
      margin-bottom: ${cssVars.distances.marginBottom};
    }

    input,
    button {
      width: 100%;
    }
  }
`;

export default StyledPasswordForgotten;
