import styled from "styled-components";
import BlockWrapper from "~/components/atoms/BlockWrapper/BlockWrapper.js";
import Icon, { IconName } from "~/components/atoms/Icon/Icon.js";
import StyledMiniInfoCardCapacity from "~/components/Dashboard/MiniInfoCardCapacity/MiniInfoCardCapacity.styles.js";
import cssVars from "~/styles/cssVars.js";

type StatsCardProps = {
  title: string;
  count: number;
  iconName: IconName;
  trendValue?: number;
};

const TrendIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  &.up {
    color: ${cssVars.colors.green};
  }

  &.down {
    color: ${cssVars.colors.red};
  }

  &.flat {
    color: ${cssVars.colors.grayDark};
  }
`;

const StatsCard = ({ title, count, iconName, trendValue }: StatsCardProps) => {
  const isValidTrent = typeof trendValue === "number" && !isNaN(trendValue);

  return (
    <StyledMiniInfoCardCapacity>
      <BlockWrapper>
        <div className="titleWrapper">
          <div className="title">{title}</div>
          {isValidTrent ? <TrendIndicator value={trendValue} /> : null}
        </div>
        <div className="countWrapper">
          <div className="count">{count}</div>
          <Icon name={iconName} size={40} className="icon" />
        </div>
      </BlockWrapper>
    </StyledMiniInfoCardCapacity>
  );
};

const TrendIndicator = ({ value }: { value: number }) => {
  type Trend = "up" | "down" | "flat";
  const trend: Trend = value === 0 ? "flat" : value > 0 ? "up" : "down";

  const trendToIconName: Record<Trend, IconName> = {
    up: "chevrons_up",
    down: "chevrons_down",
    flat: "chevrons_right",
  };

  // TODO: is there a way to get the locale here?
  const toPercentage = (number: number) =>
    number.toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });

  return (
    <TrendIndicatorWrapper className={`${trend}`}>
      <span>{toPercentage(value)}</span>

      <Icon name={trendToIconName[trend]} size={24} />
    </TrendIndicatorWrapper>
  );
};

export default function StatsCards({
  presenceZoneDataCombined,
  uniqueCounterData,
}: {
  presenceZoneDataCombined: {
    [key: string]: number;
  };
  uniqueCounterData: {
    time_stamp?: string | number;
    date?: string;
    person: number;
    runner: number;
    bike: number;
  };
}) {
  const statsCards: StatsCardProps[] = [
    {
      title: "Current Presence",
      count: presenceZoneDataCombined.total,
      iconName: "usersgroup",
      trendValue: undefined,
    },
  ];

  if (uniqueCounterData?.person > 0) {
    statsCards.push({
      title: "Walkers Today",
      count: uniqueCounterData?.person ?? 0,
      iconName: "walker",
      trendValue: undefined,
    });
  }

  if (uniqueCounterData?.runner > 0) {
    statsCards.push({
      title: "Runners Today",
      count: uniqueCounterData?.runner ?? 0,
      iconName: "runner",
      trendValue: undefined,
    });
  }

  if (uniqueCounterData?.bike > 0) {
    statsCards.push({
      title: "Cyclists Today",
      count: uniqueCounterData?.bike ?? 0,
      iconName: "cyclist",
      trendValue: undefined,
    });
  }

  return (
    <>
      {statsCards.map((card) => (
        <StatsCard
          key={card.title}
          title={card.title}
          count={Math.max(card.count, 0)}
          iconName={card.iconName}
          trendValue={card.trendValue}
        />
      ))}
    </>
  );
}
